import React from "react";
import Submenu from "component/template/submenu";
import Map from "component/company/kakaoMap";
import { Link } from "react-router-dom";

const Location = () => {
  return (
    <>
      <section className="section">
        <div className="subVisual subVisual01">COMPANY</div>
        <Submenu page={"company"} sub={"location"} />
        {/*컨텐츠 시작*/}
        <div className="inner subInner">
          <div className="location">
            <div className="map">
              <Map lat={"35.31538457922888"} lng={"129.02555219893517"} />
              <div className="mapTitle">
                Headquarters{" "}
                <Link
                  to="https://map.kakao.com/link/to/인사이트정보,35.31538457922888,129.02555219893517"
                  style={{ float: "right" }}
                  target="_blank"
                >
                  길찾기
                </Link>
              </div>
              <div className="mapTitle2">양산본사</div>
              <div className="mapDetail">
                <ul>
                  <li>
                    <img src="images/sub/location_icon01.png" alt="건물" />{" "}
                    ADDRESS
                  </li>
                  <li>경남 양산시 동면 금오로 242 401호</li>
                </ul>
                <ul>
                  <li>
                    <img src="images/sub/location_icon02.png" alt="전화번호" />{" "}
                    TEL
                  </li>
                  <li>055-372-2035</li>
                </ul>
                <ul>
                  <li>
                    <img src="images/sub/location_icon03.png" alt="팩스" /> FAX
                  </li>
                  <li>055-362-0099</li>
                </ul>
              </div>
            </div>
            <div className="map">
              <Map lat={"37.49045463800037"} lng={"127.03381514673062"} />
              <div className="mapTitle">
                Brand Office
                <Link
                  to="https://map.kakao.com/link/to/인사이트정보(서울지사),37.49045463800037,127.03381514673062"
                  style={{ float: "right" }}
                  target="_blank"
                >
                  {/* <img src="images/sub/searchIcon.png" alt="검색" /> */}
                  길찾기
                </Link>
              </div>
              <div className="mapTitle2">서울지사</div>
              <div className="mapDetail">
                <ul>
                  <li>
                    <img src="images/sub/location_icon01.png" alt="건물" />{" "}
                    ADDRESS
                  </li>
                  <li>서울시 강남구 도곡로 123, 2층 (역삼동, 대경빌딩) </li>
                </ul>
                <ul>
                  <li>
                    <img src="images/sub/location_icon02.png" alt="전화번호" />{" "}
                    TEL
                  </li>
                  <li>02-568-2035</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
};

export default Location;
