import React from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Main from "component/main";
import Footer from "component/template/footer";
import Header from "component/template/header";
import NotFound from "component/error/notFound";
import About from "component/company/about";
import Login from "component/admin/login";
import Users from "component/admin/users/userList";
import Board from "component/admin/board/boardList";
import History from "component/company/history";
import Location from "component/company/location";
import GisService from "component/business/gisService";
import WebService from "component/business/webService";
import CrowdSourcing from "component/business/crowdSourcing";
import ChatGPT from "component/business/chatGPT";
import AiMode from "component/business/aiMode";
import AiLearningData from "component/business/aiLearningData";
import AiNoiseLearningData from "component/business/aiNoiseLearningData";
import UserInsertOrUpdate from "component/admin/users/insertOrUpdate";
import UserDetail from "component/admin/users/userDetail";
import BoardList from "component/support/boardList";
import BoardView from "component/support/boardView";
import BoardWrite from "component/support/boardWrite";
import ArticleList from "component/admin/article/articleList";
import ArticleDetail from "component/admin/article/articleDetail";
import ArticleInsertOrUpdate from "component/admin/article/articleInsertOrUpdate";
import BoardInsertOrUpdate from "component/admin/board/boardInsertOrUpdate";

function App() {
  return (
    <HashRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/company/about" element={<About />} />
        <Route path="/company/history" element={<History />} />
        <Route path="/company/location" element={<Location />} />
        <Route path="/business/gisService" element={<GisService />} />
        <Route path="/business/webService" element={<WebService />} />
        <Route path="/business/crowdSourcing" element={<CrowdSourcing />} />
        <Route path="/business/chatGPT" element={<ChatGPT />} />
        <Route path="/business/aiMode" element={<AiMode />} />
        <Route path="/business/aiLearningData" element={<AiLearningData />} />
        <Route
          path="/business/aiNoiseLearningData"
          element={<AiNoiseLearningData />}
        />
        <Route path="/support/:boardCode" element={<BoardList />} />
        <Route path="/support/:boardCode/:id" element={<BoardView />} />
        <Route path="/support/boardWrite" element={<BoardWrite />} />

        <Route path="/qazx/login" element={<Login />} />
        <Route path="/qazx/users" element={<Users />} />
        <Route path="/qazx/users/insert" element={<UserInsertOrUpdate />} />
        <Route
          path="/qazx/users/:usersid/update"
          element={<UserInsertOrUpdate />}
        />
        <Route path="/qazx/users/:id" element={<UserDetail />} />
        <Route path="/qazx/board" element={<Board />} />
        <Route
          path="/qazx/board/:boardCode"
          element={<BoardInsertOrUpdate />}
        />

        <Route path="/qazx/article/:boardCode" element={<ArticleList />} />
        <Route
          path="/qazx/article/:boardCode/:id"
          element={<ArticleInsertOrUpdate />}
        />
        <Route
          path="/qazx/article/:boardCode/:id/detail"
          element={<ArticleDetail />}
        />
        <Route
          path="/qazx/article/:boardCode/insert"
          element={<ArticleInsertOrUpdate />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default App;
