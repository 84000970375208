import React from 'react'
import Submenu from 'component/template/submenu';
import { Link } from 'react-router-dom';

const AiLearningData = () => {
  return (
    <>
      <section className="section">
        <div className="subVisual subVisual02">BUSINESS</div>
        <Submenu page={"business"} sub={"ai"} />
        {/*컨텐츠 시작*/}
        <div className="tabBox">
          <ul>
            <li className="tabChoice">
              <Link to="/business/aiLearningData" state={{ scrollToSub: true }}>
                AI학습데이터
              </Link>
            </li>
            <li>
              <Link to="/business/aiMode" state={{ scrollToSub: true }}>
                AI Model
              </Link>
            </li>
            <li>
              <Link to="/business/chatGPT" state={{ scrollToSub: true }}>
                Chat GPT
              </Link>
            </li>
          </ul>
        </div>
        <div className="inner subInner">
          <div className="ai01_btn">
            <Link to="/business/aiNoiseLearningData">극한 소음 음성 및 환경소리 AI학습데이터 구축</Link>
          </div>
          <div className="bu_topBox">
            <p className="bu_topText delay">
              AI 학습 데이터는 인공지능 모델을 학습시키기 위해 사용되는 데이터로, 이 데이터를 통해 모델은 패턴과 관계를 파악하고 문제를 해결하는 방법을
              배웁니다. 일반적으로 AI 학습 데이터는 수백만 개 이상의 데이터 포인트를 포함하며, 데이터는 수집, 정제 및 주석 달기와 같은 다양한 단계를 거쳐
              처리됩니다. AI 학습 데이터는 특정 분야나 문제에 대해 라벨링된 데이터, 비정형 데이터(텍스트, 이미지, 비디오 등) 및 시뮬레이션 데이터 등이
              포함됩니다. 이러한 데이터는 학습 알고리즘의 성능을 향상시키는 데 필수적입니다.
            </p>
            <div className="bu_topImg delay">
              <img src="/images/index/buIcon04.png" alt="AI이미지" />
            </div>
          </div>
        </div>
        <div className="busineesBg">
          <div className="inner subInner">
            <div className="titleBox">
              <div className="centerBar" />
              <p className="subTitle">인사이트정보 AI학습데이터 구축</p>
            </div>
            <div className="busineesBgT">
              인사이트정보는{" "}
              <span style={{ fontSize: "1.2em" }} className="ai_pointText">
                AI 학습데이터 구축 선도적인 전문업체
              </span>
              입니다.
              <br />
              인사이트정보에서 구축한 AI DATA SET은 한국지능정보사회진흥원 AI Hub (
              <a style={{ textDecoration: "underline", color: "#1a379f" }} href="https://aihub.or.kr/" target="_blank">
                https://aihub.or.kr/
              </a>
              ) 다운로드 가능 합니다.
            </div>
            <img src="images/sub/ai_img01.png" className="busineesBgTImg" alt="진흥원마크" />
            <img src="images/sub/ai_img08.png" style={{ height: 50, marginLeft: 10 }} className="busineesBgTImg" alt="AIhub" />
            <ul className="history01Text" style={{ width: "100%", textAlign: "center", marginTop: "2em" }}>
              <li>(2020년) 환경오염 AI 데이터</li>
              <li>(2021년) 소음 환경 음성인식 데이터</li>
              <li>(2022년) 극한 소음 음성 및 환경 소리 데이터</li>
              <li>(2022년) 연령대별 특징적 발화(은어·속어 등) 음성 데이터</li>
              <li>(2023년) 해외 언어 원천의 말뭉치 데이터</li>
              <li>(2023년) 다양한 소음원에서의 Ground Truth 지식정보데이터</li>
            </ul>
            {/*div class="aiListBox">
                  <div class="aiListW">
                      <div class="aiList">
                          <p>AI학습데이터 구축 프로세스 자동화 도구 자체 보유</p>
                          <div>
                              AI모델 학습에 필수적인 데이터 수집, 정제, 전처리 과정을 자동화하여 작업 효율성을 높이고 인력 비용을 절감할 수 있는 장점이 있습니다.<br />
                              이러한 자동화 도구는 일반적으로 크롤링, 필터링, 데이터 변환, 라벨링 등의 과정을 수행할 수 있으며, 다양한 데이터 형식을 지원하고 있습니다. 또한, 사용자가 직접 학습 데이터를 수집하는 것보다 더욱 정확하고 일관된 데이터를 수집할 수 있기 때문에 학습 결과의 품질을 향상시킬 수 있습니다.
                          </div>
                      </div>
                  </div>
                  <div class="aiListW">
                      <div class="aiList">
                          <p>AI학습데이터 구축 프로세스 자동화 도구 자체 보유</p>
                          <div>
                              다년간의 AI 학습데이터 구축 노하우 축척은 데이터 수집 및 라벨링에 대한 전문 지식, 기술, 프로세스 등을 축적하고 관리하는 것을 의미합니다. 
                              이는 데이터 구축의 전 과정에서 발생할 수 있는 문제점을 해결하고 최적화하기 위해 필요합니다.
                              데이터 구축에 있어서 문제점은 매우 다양합니다. 예를 들어, 데이터가 부족하거나 불균형한 경우, 라벨링 오류, 데이터 품질 문제,
                              시간과 예산의 한계 등이 있습니다. 이러한 문제점은 AI 모델의 정확성과 성능에 직접적인 영향을 미칠 수 있기 때문에, 이를 최소화하기 위한 노하우가 필요합니다.
                          </div>
                      </div>
                  </div>
                  <div class="aiListW">
                      <div class="aiList">
                          <p>AI학습데이터 구축 프로세스 자동화 도구 자체 보유</p>
                          <div>
                              다년간의 AI 학습데이터 구축 노하우 축척은 <span class="ai_pointText">데이터 수집 및 라벨링에 대한 전문 지식, 기술, 프로세스 등을 축적하고 관리하는 것을 의미</span>합니다. 이는 데이터 구축의 전 과정에서 발생할 수 있는 문제점을 해결하고 최적화하기 위해 필요합니다.
                              데이터 구축에 있어서 문제점은 매우 다양합니다. 예를 들어, 데이터가 부족하거나 불균형한 경우, 라벨링 오류, 데이터 품질 문제, 시간과 예산의 한계 등이 있습니다. 이러한 문제점은 AI 모델의 정확성과 성능에 직접적인 영향을 미칠 수 있기 때문에, 이를 최소화하기 위한 노하우가 필요합니다.
                          </div>
                      </div>
                  </div>
                  <div class="aiListW">
                      <div class="aiList">
                          <p>AI학습데이터 구축 프로세스 자동화 도구 자체 보유</p>
                          <div>
                              AI 학습데이터 품질 전담조직은 다음과 같은 역할을 수행합니다.
                              <ul style="text-align: left;">
                                  <li> 1. <span class="ai_pointText">데이터 품질 관리:</span> AI 학습데이터의 품질 문제를 식별하고, 해결하기 위한 방법을 개발하고 적용합니다. 이를 위해, 데이터 라벨링 오류 검증 및 수정, 이상치 탐지, 데이터 불균형 해결 등의 기술을 사용합니다.</li>
                                  <li> 2. <span class="ai_pointText">프로세스 개선:</span> 데이터 수집 및 라벨링 작업의 프로세스를 최적화하고, 작업 시간과 예산을 절약하는 방법을 연구합니다. 이를 위해서는 작업 흐름 및 작업자 간 역할 분담 등을 조정하는 방법을 익힙니다.</li>
                                  <li> 3. <span class="ai_pointText">기술 도입:</span> AI 모델 학습을 위한 데이터 구축 기술을 습득하고, 최신 기술 도입에 대한 검토와 구현을 수행합니다. 이를 통해 AI 모델의 정확성과 성능을 향상시키는 방법을 익힙니다.</li>
                                  <li> 4. <span class="ai_pointText">데이터 보안 관리:</span> AI 학습데이터의 보안을 관리합니다. 이를 위해, 데이터의 저장 및 전송 방식 등을 조정하고, 보안 위험에 대한 대비 계획을 수립합니다.</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div*/}
            <div className="ai01_textList">
              <div className="ai01_tLTitle">AI학습데이터 구축 프로세스 자동화 도구 자체 보유</div>
              <p className="ai01_tLCon">
                AI모델 학습에 필수적인 데이터 수집, 정제, 전처리 과정을 자동화하여 작업 효율성을 높이고 인력 비용을 절감할 수 있는 장점이 있습니다.
                <br />
                이러한 자동화 도구는 일반적으로 크롤링, 필터링, 데이터 변환, 라벨링 등의 과정을 수행할 수 있으며, 다양한 데이터 형식을 지원하고 있습니다. 또한,
                사용자가 직접 학습 데이터를 수집하는 것보다 더욱 정확하고 일관된 데이터를 수집할 수 있기 때문에 학습 결과의 품질을 향상시킬 수 있습니다.
              </p>
              <div className="ai01_tLTitle">대규모 구축을 위한 크라우드워커(CrowdWorker) 인력 Pool</div>
              <p className="ai01_tLCon">
                다년간의 AI 학습데이터 구축 노하우 축척은 데이터 수집 및 라벨링에 대한 전문 지식, 기술, 프로세스 등을 축적하고 관리하는 것을 의미합니다. 이는
                데이터 구축의 전 과정에서 발생할 수 있는 문제점을 해결하고 최적화하기 위해 필요합니다. 데이터 구축에 있어서 문제점은 매우 다양합니다. 예를 들어,
                데이터가 부족하거나 불균형한 경우, 라벨링 오류, 데이터 품질 문제, 시간과 예산의 한계 등이 있습니다. 이러한 문제점은 AI 모델의 정확성과 성능에
                직접적인 영향을 미칠 수 있기 때문에, 이를 최소화하기 위한 노하우가 필요합니다.
              </p>
              <div className="ai01_tLTitle">다년간의 AI학습데이터 구축 노하우</div>
              <p className="ai01_tLCon">
                다년간의 AI 학습데이터 구축 노하우 축척은{" "}
                <span className="ai_pointText">데이터 수집 및 라벨링에 대한 전문 지식, 기술, 프로세스 등을 축적하고 관리하는 것을 의미</span>
                합니다. 이는 데이터 구축의 전 과정에서 발생할 수 있는 문제점을 해결하고 최적화하기 위해 필요합니다. 데이터 구축에 있어서 문제점은 매우
                다양합니다. 예를 들어, 데이터가 부족하거나 불균형한 경우, 라벨링 오류, 데이터 품질 문제, 시간과 예산의 한계 등이 있습니다. 이러한 문제점은 AI
                모델의 정확성과 성능에 직접적인 영향을 미칠 수 있기 때문에, 이를 최소화하기 위한 노하우가 필요합니다.
              </p>
              <div className="ai01_tLTitle">AI학습데이터 품질 전담 조직</div>
              <div className="ai01_tLCon">
                AI 학습데이터 품질 전담조직은 다음과 같은 역할을 수행합니다.
                <ul>
                  <li>
                    {" "}
                    1. <span className="ai_pointText">데이터 품질 관리:</span> AI 학습데이터의 품질 문제를 식별하고, 해결하기 위한 방법을 개발하고 적용합니다.
                    이를 위해, 데이터 라벨링 오류 검증 및 수정, 이상치 탐지, 데이터 불균형 해결 등의 기술을 사용합니다.
                  </li>
                  <li>
                    {" "}
                    2. <span className="ai_pointText">프로세스 개선:</span> 데이터 수집 및 라벨링 작업의 프로세스를 최적화하고, 작업 시간과 예산을 절약하는
                    방법을 연구합니다. 이를 위해서는 작업 흐름 및 작업자 간 역할 분담 등을 조정하는 방법을 익힙니다.
                  </li>
                  <li>
                    {" "}
                    3. <span className="ai_pointText">기술 도입:</span> AI 모델 학습을 위한 데이터 구축 기술을 습득하고, 최신 기술 도입에 대한 검토와 구현을
                    수행합니다. 이를 통해 AI 모델의 정확성과 성능을 향상시키는 방법을 익힙니다.
                  </li>
                  <li>
                    {" "}
                    4. <span className="ai_pointText">데이터 보안 관리:</span> AI 학습데이터의 보안을 관리합니다. 이를 위해, 데이터의 저장 및 전송 방식 등을
                    조정하고, 보안 위험에 대한 대비 계획을 수립합니다.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="ai01_03">
          <div className="titleBox">
            <div className="centerBar" />
            <p className="subTitle">인사이트정보 AI학습데이터 구축 절차</p>
          </div>
          <div className="inner subInner" style={{ overflow: "initial" }}>
            <ul className="ai01_03Step">
              <li>
                <p>목표 설정</p>
                <div>AI 모델의 목표를 설정합니다. 어떤 문제를 해결하고자 하는지, 모델이 수행할 작업이 무엇인지를 명확하게 정의합니다.&gt;</div>
              </li>
              <li>
                <p>데이터 수집</p>
                <div>
                  데이터는 다양한 곳에서 수집될 수 있습니다. 예를 들어, 인터넷에서 크롤링하여 수집하거나, 이미 구축된 데이터베이스에서 가져오거나, 직접 수집하는
                  등의 방법이 있습니다.
                </div>
              </li>
              <li>
                <p>데이터 정제</p>
                <div>
                  수집한 데이터는 분석 및 모델링을 위해 전처리 과정을 거쳐야 합니다. 이 과정에서는 데이터를 정제하고, 불필요한 정보를 제거하며, 형식을
                  일치시키고, 누락된 데이터를 채우는 등의 작업을 수행합니다.
                </div>
              </li>
              <li>
                <p>데이터 라벨링</p>
                <div>모델이 학습할 수 있도록 데이터에 라벨을 붙입니다. 라벨은 모델이 예측해야 하는 결과값입니다.</div>
              </li>
              <li>
                <p>데이터 구조화</p>
                <div>
                  구조화 과정은 검수 완료된 데이터를 취합하여 폴더를 최종적으로 구조화 하는 과정입니다. 인공지능 모델은 한 번 학습된 후 끝나는 것이 아니라, 여러
                  번의 고도화 과정을 거치게 됩니다.
                </div>
              </li>
            </ul>
            <p style={{ fontSize: ".85em", textAlign: "left" }}>
              * <span className="ai_pointText">크라우드 소싱(Crowd Sourcing) :</span> 대중(Crowd)과 아웃소싱(Outsourcing)의 합성어로 언제 어디서, 누구나 온라인
              플랫폼을 통해 데이터의 수집, 정제, 가공, 검토 등의 활동을 하는 것을 말한다.[출처] 대한민국 정책브리핑(www.korea.kr)
              <br />* <span className="ai_pointText">크라우드 워커(Crowd Worker) :</span> 국립국어원에서 권고한 한글 명으로는 “대규모 참여형 노동자” 라 불리우며
              대중(Crowd)과 참여자(Worker)의 합성어로 크라우드 소싱에 참여하는 사람을 말하는 것이었다.[출처] 대한민국 정책브리핑(www.korea.kr)
              <br />* <span className="ai_pointText">데이터셋(Data Set) :</span> 인공지능의 기계학습에 사용하는 원천데이터와 라벨링 데이터의 묶음을 말하며,
              사용하는 목적에 따라 ‘훈련 데이터셋’, ‘검증 데이터셋’, ‘시험 데이터셋’ 으로 구분 함.
            </p>
          </div>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
}

export default AiLearningData;