import { atom } from "recoil";

export const showModal = atom({
  key: "Modal",
  default: false
})

export const userInfoState = atom({
  key: 'userInfoState',
  default: JSON.parse(localStorage.getItem("userInfo"))
});

export const historyState = atom({
  key: 'historyState',
  default: "재도약기"
});

