import React, { useRef, useState, useEffect } from "react";
import Submenu from "component/template/submenu";
import { Link, useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

import ArticleService from "service/article";

const BoardView = () => {
  const { boardCode } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();

  const phoneRef = useRef();

  const [article, setArticle] = useState({
    id: "",
    title: "",
    content: "",
    createdByName: "",
    createdDate: "",
  });

  useEffect(() => {
    if (id !== "insert") {
      //게시글 조회
      ArticleService.getArticle(id)
        .then((response) => {
          setArticle(response.data);
        })
        .catch((error) => {
          // console.log("getArticle error::", error);
        });
    }
  }, []);

  const updateField = (e, name) => {
    if (name === "agreePrivate") {
      setArticle({
        ...article,
        [name]: e.target.checked,
      });
    } else if (name === "phoneNumber") {
      setArticle({
        ...article,
        [name]: e,
      });
    } else {
      setArticle({
        ...article,
        [name]: e.target.value,
      });
    }
  };

  const onChangePhoneNumber = (e, name) => {
    const phoneNumber = phoneRef.current.value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

    phoneRef.current.value = phoneNumber;
    updateField(phoneNumber, name);
  };

  const onSubmit = () => {
    let emailReg =
      /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;

    if (article.createdByName == null || article.createdByName === "") {
      alert("이름을 입력해주세요.");
      return;
    }
    if (article.phoneNumber == null || article.phoneNumber === "") {
      alert("연락처를 입력해주세요.");

      return;
    }
    if (article.email == null || article.email === "") {
      alert("이메일을 입력해주세요.");
      return;
    }
    if (article.content == null || article.content === "") {
      alert("문의 내용을 입력해주세요.");
      return;
    }
    if (!emailReg.test(article.email)) {
      alert("이메일 형식을 다시 확인해주세요.");
      return;
    }
    if (article.agreePrivate == null || article.agreePrivate === "") {
      alert("개인정보처리방침에 동의해주세요.");
      return;
    }

    if (window.confirm("게시글을 저장하시겠습니까?")) {
      ArticleService.saveArticle(article, boardCode).then((response) => {
        if (response.status === 200) {
          alert("저장이 완료되었습니다.");
          navigate("/support/qna");
        }
      });
    }
  };

  return (
    <>
      <section className="section">
        <div className="subVisual subVisual03">SUPPORT</div>
        <Submenu page={"support"} />
        {/*컨텐츠 시작*/}
        {article.id !== "" && article.id !== "insert" ? (
          //게시글 조회
          <div className="inner subInner">
            {/*게시판 상세보기 시작*/}
            <div className="boardView_Wrap">
              <div className="boardView_info">
                <div className="boardViewTitle">{article.title}</div>
                <ul className="boardViewUtil">
                  <li>작성일</li>
                  <li>{article.createdDate}</li>
                  <li>작성자</li>
                  <li>{article.createdByName}</li>
                </ul>
              </div>
              {/* <div className="boardViewCon">{article.content}</div> */}
              <div
                className="boardViewCon"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(article.content),
                }}
              ></div>
            </div>
            {/*게시판 상세보기 끝*/}
            <ul className="btnBox btnRight subLastBottom">
              <li className="btnColor">
                <Link to={`/support/${boardCode}`}>목 록</Link>
              </li>
            </ul>
          </div>
        ) : (
          //사용자 게시글 작성(/support/qna/insert 인 경우)
          <div className="inner subInner">
            {/*게시판 쓰기,수정 시작*/}
            <table className="board boardWrite tableBlock">
              <tbody>
                <tr>
                  <th>
                    <span>*</span>이름
                  </th>
                  <td>
                    <input
                      onChange={(e) => {
                        updateField(e, "createdByName");
                      }}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>*</span>연락처
                    {/* <small>&nbsp;&nbsp;(숫자만 입력해주세요.)</small> */}
                  </th>
                  <td>
                    <input
                      ref={phoneRef}
                      onChange={(e) => {
                        //updateField(e, "phoneNumber");
                        onChangePhoneNumber(e, "phoneNumber");
                      }}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor="boardMail">
                      <span>*</span>이메일
                    </label>
                  </th>
                  <td>
                    <input
                      //type="email"
                      //id="boardMail"
                      onChange={(e) => {
                        updateField(e, "email");
                      }}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <th>
                    <label htmlFor="boardTitle">
                      <span>*</span>제목
                    </label>
                  </th>
                  <td>
                    <input type="text" id="boardTitle" />
                  </td>
                </tr> */}
                <tr>
                  <th>
                    <label htmlFor="boardWriteCon">
                      <span>*</span>내용
                    </label>
                  </th>
                  <td>
                    <textarea
                      rows={20}
                      cols={30}
                      id="boardWriteCon"
                      defaultValue={""}
                      onChange={(e) => updateField(e, "content")}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <th>
                    <label htmlFor="boardWritePassword">비밀번호</label>
                  </th>
                  <td>
                    <input type="password" id="boardWritePassword" />
                    <p>※ 수정/삭제시 필요하오니 반드시 기억하시기 바랍니다.</p>
                  </td>
                </tr> */}
              </tbody>
            </table>
            {/*게시판 쓰기,수정 끝*/}
            <ul className="btnBox btnBoxRight subLastBottom">
              <li style={{ marginRight: "12px" }}>
                <input
                  type="checkbox"
                  id="agree"
                  onChange={(e) => updateField(e, "agreePrivate")}
                />
                <label for="agree">&nbsp;&nbsp;개인정보처리방침 동의</label>
              </li>
              <li className="btnColor">
                <Link to="#" type="button" onClick={onSubmit}>
                  등록
                </Link>
              </li>
              <li>
                <Link to={`/support/${boardCode}`}>목록</Link>
              </li>
            </ul>
          </div>
        )}
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
};

export default BoardView;
