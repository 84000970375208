import React, { useRef, useState, useEffect } from "react";
import Submenu from "component/template/submenu";
import { Link, useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

import ArticleService from "service/article";
import BoardService from "service/board";
import Paging from "component/template/paging";

const BoardList = () => {
  const { boardCode } = useParams();
  const navigate = useNavigate();

  const keywordRef = useRef();
  const searchTypedRef = useRef();
  const scrollRef = useRef();

  const [articleList, setArticleList] = useState([]);
  const [searchType, setSearchType] = useState("title");
  const [keyword, setKeyword] = useState("");
  const [skin, setSkin] = useState("");

  //페이징
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5); //아마 한 페이지당 표시할 게시글 개수(?)
  const [totalElements, setTotalElements] = useState(0);

  //게시판별 전체 게시글 불러오기
  const getArticles = () => {
    ArticleService.getArticles(boardCode, currentPage)
      .then((response) => {
        let articleList = response.data.content;
        let i = currentPage * 20 + 1;
        articleList.map((article) => {
          article.index = i++;
          return article;
        });

        setArticleList(articleList); //20개
        setTotalElements(response.data.totalElements); //30개
        setPerPage(response.data.size); //20
      })
      .catch((error) => {
        // console.log("게시글 불러오기 실패::", error);
      });
  };

  const toMain = () => {
    if (window.confirm("메인 화면의 문의를 이용해주세요")) {
      navigate("/");
    }
  };

  useEffect(() => {
    getArticles();

    BoardService.getBoardByCode(boardCode).then((response) => {
      setSkin(response.data.skin);
    });

    //검색 조건 초기화
    if (boardCode === "qna") {
      setSearchType("createdByName");
    } else {
      setSearchType("title");
    }

    //검색 키워드 초기화
    setKeyword("");
  }, [boardCode]);

  useEffect(() => {
    if (searchTypedRef.current) {
      searchTypedRef.current.value = searchType;
    }
  }, [searchType]);

  useEffect(() => {
    if (keywordRef.current) {
      keywordRef.current.value = keyword;
    }
  }, [keyword]);

  //todo
  //1. 전체 게시글 중에서 currentPage 바뀐 경우랑 search에서 페이지 바뀐 경우 분리하기
  //2. 페이지 변경시 번호 1번부터 시작하는 부분 수정

  useEffect(() => {
    if (keyword === "") {
      getArticles();
    } else {
      searchArticle();
    }
  }, [currentPage]);

  const searchArticle = () => {
    ArticleService.searchArticle(boardCode, searchType, keyword, currentPage)
      .then((response) => {
        let articleList = response.data.content;
        let i = currentPage * 20 + 1;
        articleList.map((article) => {
          article.index = i++;
          return article;
        });

        setArticleList(articleList); //20개
        setTotalElements(response.data.totalElements); //30개
        setPerPage(response.data.size); //20
      })
      .catch((error) => {
        // console.log("검색 중 오류", error);
      });
  };

  return (
    <>
      <section className="section">
        <div className="subVisual subVisual03">SUPPORT</div>
        <div
          ref={(ref) => {
            scrollRef.current = ref;
          }}
        >
          <Submenu page={"support"} sub={boardCode} />
        </div>
        {/*컨텐츠 시작*/}
        <div className="inner subInner">
          {skin && skin === "notice" ? (
            //스킨이 notice일 때
            <>
              {boardCode === "recruitmentNotice" && (
                <>
                  <h2 className="employText">
                    인사이트정보는
                    <br />
                    여러분의 열정적 도전을 기다립니다.
                  </h2>
                  <div className="employStepBox">
                    <div className="employStep">
                      <div>
                        <img
                          src="images/sub/employ01.png"
                          alt="서류전형"
                        />
                        <p>서류전형</p>
                      </div>
                    </div>
                    <div className="employStep">
                      <div>
                        <img
                          src="images/sub/employ02.png"
                          alt="실무면접"
                        />
                        <p>실무면접</p>
                      </div>
                    </div>
                    <div className="employStep">
                      <div>
                        <img
                          src="images/sub/employ03.png"
                          alt="인성면접"
                        />
                        <p>인성면접</p>
                      </div>
                    </div>
                    <div className="employStep">
                      <div>
                        <img
                          src="images/sub/employ04.png"
                          alt="합격자 발표"
                        />
                        <p>합격자 발표</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/*검색창 시작*/}
              <div className="searchBox">
                {boardCode === "qna" ? (
                  <select
                    ref={(ref) => (searchTypedRef.current = ref)}
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    <option value="createdByName">작성자</option>
                  </select>
                ) : (
                  <select
                    ref={(ref) => (searchTypedRef.current = ref)}
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    <option value="title">제목</option>
                    <option value="content">내용</option>
                    <option value="createdByName">작성자</option>
                  </select>
                )}

                <div>
                  <input
                    type="text"
                    ref={(ref) => (keywordRef.current = ref)}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <button
                    onClick={(e) => {
                      setCurrentPage(0); //검색시 1페이지로 이동
                      searchArticle();
                    }}
                  >
                    <img src="images/sub/searchIcon.png" alt="검색" />
                  </button>
                </div>
              </div>
              {/*검색창 끝*/}
              {/*게시판 목록 시작*/}
              <table className="board boardList">
                <caption>게시판</caption>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>제목</th>
                    <th>작성자</th>
                    <th>작성일</th>
                    <th>조회</th>
                  </tr>
                </thead>
                <tbody>
                  {articleList &&
                    articleList.length > 0 &&
                    articleList.map((article) => {
                      return (
                        <tr key={article.id}>
                          {/* <td className="boardNum">{article.id}</td> */}
                          <td className="boardNum">{article.index}</td>
                          <td className="textLeft boardTitle">
                            {boardCode === "qna" ? (
                              <Link to={`/support/${boardCode}/${article.id}`}>
                                *비밀글입니다.
                              </Link>
                            ) : (
                              <Link to={`/support/${boardCode}/${article.id}`}>
                                {article.title}
                              </Link>
                            )}
                          </td>
                          <td className="boardName">{article.createdByName}</td>
                          <td className="boardDate">{article.createdDate}</td>
                          <td className="boardHit">{article.views}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {/*게시판 목록 끝*/}
              {/*페이지 시작*/}
              <Paging
                perPage={perPage}
                totalElements={totalElements}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              {/*페이지 끝*/}
            </>
          ) : skin === "qna" ? (
            <>
              {/*검색창 시작*/}
              <div className="searchBox">
                {boardCode === "qna" ? (
                  <select
                    ref={(ref) => (searchTypedRef.current = ref)}
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    <option value="createdByName">작성자</option>
                  </select>
                ) : (
                  <select
                    ref={(ref) => (searchTypedRef.current = ref)}
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    <option value="title">제목</option>
                    <option value="content">내용</option>
                    <option value="createdByName">작성자</option>
                  </select>
                )}
                <div>
                  <input
                    type="text"
                    ref={(ref) => (keywordRef.current = ref)}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <button
                    onClick={(e) => {
                      setCurrentPage(0); //검색시 1페이지로 이동
                      searchArticle();
                    }}
                  >
                    <img src="images/sub/searchIcon.png" alt="검색" />
                  </button>
                </div>
              </div>
              {/*검색창 끝*/}
              {/*게시판 목록 시작*/}
              <table className="board boardList">
                <caption>게시판</caption>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>제목</th>
                    <th>작성자</th>
                    <th>작성일</th>
                    {/* <th>조회</th> */}
                  </tr>
                </thead>
                <tbody>
                  {articleList &&
                    articleList.length > 0 &&
                    articleList.map((article) => {
                      return (
                        <tr key={article.id}>
                          {/* <td className="boardNum">{article.id}</td> */}
                          <td className="boardNum">{article.index}</td>
                          <td className="textLeft boardTitle">
                            <Link to={`/support/${boardCode}/${article.id}`}>
                              *비밀글입니다.
                            </Link>
                          </td>
                          <td className="boardName">{article.createdByName}</td>
                          <td className="boardDate">{article.createdDate}</td>
                          {/* <td className="boardHit">{article.views}</td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {/*게시판 목록 끝*/}
              {/*페이지 시작*/}
              <Paging
                perPage={perPage}
                totalElements={totalElements}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              {/*페이지 끝*/}
              <ul className="btnBox btnBoxRight">
                <li>
                  <Link
                    to={`/support/${boardCode}/insert`}
                    className="btnColor" /*onClick={toMain}*/
                  >
                    <span>글쓰기</span>
                  </Link>
                </li>
              </ul>
            </>
          ) : skin === "faq" ? (
            <>
              {/*게시판 목록 시작*/}
              {articleList &&
                articleList.length > 0 &&
                articleList.map((article) => {
                  return (
                    <table className="board boardList">
                      <caption>게시판</caption>

                      <tbody>
                        <tr>
                          <th>Q.</th>
                          <td>{article.title}</td>
                        </tr>
                        <tr>
                          <th>A.</th>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(article.content),
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
              {/*게시판 목록 끝*/}
            </>
          ) : skin === "gallery" ? (
            //추후 스킨 추가시 수정
            <></>
          ) : (
            <></>
          )}
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
};

export default BoardList;
