import {atom} from "recoil";

export const searchValueState = atom({
    key: "searchValueState",
    default: {
        "type": "",
        "keyword": ""
    }
});

export const currentPageState = atom({
    key: "currentPageState",
    default: 0,
});

export const totalElementsState = atom({
    key: "totalElementsState",
    default: 0,
});

export const sizeState = atom({
    key: "sizeState",
    default: 5,
});

export const sortState = atom({
    key: "sortState",
    default: "id%2Cdesc",
});

export const selectConditionsState = atom({
    key: "selectConditionsState",
    default: [],
});

export const typeOptionsState = atom({
    key: "typeOptionsState",
    default: [],
})

export const areaSearchValuesState = atom({
    key: "areaSearchValuesState",
    default: ""
})
