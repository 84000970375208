import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { showModal } from "_states/atom";
import { scroller } from "react-scroll";

const Topnavigation = ({ isMobile }) => {
  const [Modal, setModal] = useRecoilState(showModal);
  const modalRef = useRef(null);

  const closedModal = () => {
    setModal(false);
  };

  // 모달창 외부 클릭 시 닫히도록
  const handleClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closedModal();
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClick);
  }, [modalRef]);

  return (
    <>
      {isMobile === false ? (
        //웹
        <div className="siteMapBox">
          <button onClick={closedModal}>
            <img src="images/searchIcon2.png" alt="닫기" />
          </button>
          <div className="siteMap" ref={modalRef}>
            <div className="sMList">
              <h4>COMPANY</h4>
              <ul>
                <li>
                  <Link to="/company/about" onClick={closedModal}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/company/history" onClick={closedModal}>
                    CEO & History
                  </Link>
                </li>
                <li>
                  <Link to="/company/location" onClick={closedModal}>
                    오시는길
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sMList">
              <h4>BUSINESS</h4>
              <ul>
                <li>
                  <Link to="/business/aiLearningData" onClick={closedModal}>
                    AI
                  </Link>
                </li>
                <li>
                  <Link to="/business/gisService" onClick={closedModal}>
                    GIS Service
                  </Link>
                </li>
                <li>
                  <Link to="/business/webService" onClick={closedModal}>
                    WEB Service
                  </Link>
                </li>
                <li>
                  <Link to="/business/crowdSourcing" onClick={closedModal}>
                    CROWD Sourcing
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sMList">
              <h4>SUPPORT</h4>
              <ul>
                <li>
                  <Link to="/support/notice" onClick={closedModal}>
                    공지사항
                  </Link>
                </li>
                <li>
                  <Link to="/support/recruitmentNotice" onClick={closedModal}>
                    채용안내
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        //모바일
        <nav className="mobileMenuBg">
          <div className="mobileMenu_Wrap" ref={modalRef}>
            <button className="mobileM_btn" onClick={closedModal}>
              x
            </button>
            <div className="mobileMenu">
              <h4>COMPANY</h4>
              <ul className="mobileM2step">
                <li>
                  <Link to="/company/about" onClick={closedModal}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/company/history" onClick={closedModal}>
                    CEO & History
                  </Link>
                </li>
                <li>
                  <Link to="/company/location" onClick={closedModal}>
                    오시는길
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mobileMenu">
              <h4>BUSINESS</h4>
              <ul className="mobileM2step">
                <li>
                  <Link to="/business/aiLearningData" onClick={closedModal}>
                    AI
                  </Link>
                </li>
                <li>
                  <Link to="/business/gisService" onClick={closedModal}>
                    GIS Service
                  </Link>
                </li>
                <li>
                  <Link to="/business/webService" onClick={closedModal}>
                    WEB Service
                  </Link>
                </li>
                <li>
                  <Link to="/business/crowdSourcing" onClick={closedModal}>
                    CROWD Sourcing
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mobileMenu">
              <h4>SUPPORT</h4>
              <ul className="mobileM2step">
                <li>
                  <Link to="/support/notice" onClick={closedModal}>
                    공지사항
                  </Link>
                </li>
                <li>
                  <Link to="/support/recruitmentNotice" onClick={closedModal}>
                    채용안내
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default Topnavigation;
