import React from 'react'
import Submenu from 'component/template/submenu';

const CrowdSourcing = () => {
  return (
    <>
      <section className="section">
        <div className="subVisual subVisual02">BUSINESS</div>
        <Submenu page={"business"} sub={"crowdSourcing"} />
        {/*컨텐츠 시작*/}
        <div className="ai02_01">
          <div className="inner subInner">
            <div className="titleBox">
              <div className="centerBar" />
              <div className="subTitle">CROWD Sourcing</div>
            </div>
            <div className="bu_topBox">
              <p className="bu_topText delay">
                크라우드소싱은 생산과 서비스 과정에 소비자 혹은 대중을 참여하도록 개방하여 생산 효율을 높이고 수익을 참여자와 공유하는 방법으로 '대중(Crowd)'과
                '외부 자원 활용'(Out sourcing)의 합성어이다. 소비자들이 제품 기획 단계에서부터 참여, 소비자들의 요구를 반영한 상품 개발뿐만 아니라 초기부터
                소비자들은 자신의 아이디어가 반영되는 제품을 홍보할 수 있어 기업은 최소 비용으로 최대 효과를 창출할 수 있는 방법으로 이용하고 있다.
              </p>
              <div className="bu_topImg delay">
                <img className="imgSize01" src="images/sub/bu_03.png" alt="크라우드삼각형" />
              </div>
            </div>
          </div>
          <div className="busineesBg">
            <div className="inner subInner">
              <div className="ai01_tLTitle ai01_tLTitle03" style={{ marginTop: 0 }}>
                데이터 라벨링
              </div>
              <div className="ai03_01 ai03_01_02 ai01_tLConHalf" style={{ paddingTop: "1em" }}>
                <div className="history01Img">
                  <img src="images/sub/bu_03_02.png" alt="데이터라벨링이미지" />
                </div>
                <ul className="history01Text history01Text04">
                  <li>
                    사진/문서 등 사람이 만든 데이터를 인공지능(AI)이 인식할 수 있도록 재가공하는 작업. AI가 학습할 데이터를 수집/가공해 고품질의 데이터셋을
                    구축한다.
                  </li>
                </ul>
              </div>
              <div className="ai01_tLTitle ai01_tLTitle03">사업 수행사례</div>
              <div className="ai01Sub">
                <div className="ai01SubText" style={{ width: "100%" }}>
                  <div className="ai01SubText01">
                    <ul className="history01Text" style={{ width: "100%" }}>
                      <li className="checkTextT">국가 건물에너지 통합관리 시스템 구축</li>
                      <div className="ai01_tLCon ai01_tLConHalf">
                        <div className="ai_imgBox ai_imgBox2">
                          <img style={{ border: "1px solid #ddd" }} src="images/sub/cr_02.png" alt="국가건물에너지운영기관이미지" />
                        </div>
                        <span className="ai_textBox ai_textBox2">
                          국가건물에너지통합관리시스템은 전국 약 90여개의 에너지 유관기관으로부터 에너지사용량정보(전기, 도시가스, 지역난방)를 수집/변환하여
                          통합DB 및 DW/DM을 구축하여 활용한다.
                          <br />
                          <br />
                          - 매월 유관기관에서 수집된 자료는 텍스트 형태(txt, excel, 기타 등)로 수집되고 있으며, 데이터통합툴을 활용하여 자료입력원이 주소 데이터
                          기반으로 변환 및 매칭 후 검증 단계를 진행 후 통합DB 데이터를 구축
                          <br />
                          - 유관기관의 에너지자료는 매월 신규로 생성이 되므로, 자료 수집 및 변환, 매칭 등의 작업이 필요
                          <br />- 유관기관의 제공자료 포맷이 다양하므로, 전국 17개 시도의 자료를 자료 입력원이 수동으로 변환 및 매칭 작업을 진행
                        </span>
                      </div>
                      <br />
                      <br />
                      <li className="checkTextT">건설정보관리시스템 구축</li>
                      <div className="ai01_tLCon ai01_tLConHalf">
                        <div className="ai_imgBox ai_imgBox2">
                          <img style={{ border: "1px solid #ddd" }} src="images/sub/cr_01.png" alt="건설정보관리시스템이미지" />
                        </div>
                        <span className="ai_textBox ai_textBox2">
                          건설정보관리시스템은 부산광역시의 본청 및 16개 구군의 건설정보(설계서, 도면 등)를 효율적으로 관리하여 신속한 행정 업무처리를 위한
                          통합관리시스템을 구축한다.
                          <br />
                          <br />
                          - 건설(토목)공사의 원활한 사업진행을 위한 일정 및 체크리스트 관리를 위한 공정관리
                          <br />
                          - 공사관리, 도면 및 산출물관리, 공사위치관리
                          <br />- 본청 및 16개 구군의 자료를 수집(CD, USB, 담당자 PC), 분류 및 정제 후 DB구축하여 활용
                        </span>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
}

export default CrowdSourcing;