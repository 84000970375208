import React, { useEffect, useState } from "react";
import Navigation from "../navigation";
import AdminHeader from "../adminHeader";
import UserService from "service/user.js";
import { Link, useLocation } from "react-router-dom";
import Pagination from "component/template/pagination";
import { useRecoilState } from "recoil";
import { totalElementsState } from "_states/search";
import Loading from "component/template/Loading";

const UserList = () => {
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [totalElements, setTotalElements] = useRecoilState(totalElementsState);

  const location = useLocation();

  useEffect(() => {
    fetchdUser(location.search);
  }, [location]);

  const fetchdUser = (parameters) => {
    setLoading(true);
    UserService.findUserList(parameters).then((response) => {
      // console.log(response);
      if (response.status === 200) {
        setUserList(response.data.content);
        setTotalElements(response.data.totalElements);
      }
      // console.log(totalElements);
      setLoading(false);
    });
  };

  return (
    <>
      <AdminHeader />
      <Navigation />
      <section id="section" className="adminSection">
        <div className="subTitle">
          계정 관리
          <div className="subUtilBox">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>{" "}
              &gt;{" "}
              <li>
                <a href="#">계정 관리</a>
              </li>{" "}
            </ul>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              <img
                src="images/printicon.png"
                width={16}
                height={16}
                alt="인쇄하기"
              />
            </Link>
          </div>
        </div>
        {/*컨텐츠시작*/}
        <div className="contents">
          <p className="tableTitle">계정 관리</p>
          {loading ? <Loading /> : null}
          {/*테이블*/}
          <table>
            <caption>계정 관리</caption>
          </table>
          <table className="tableMove">
            <tbody>
              <tr>
                <th>아이디</th>
                <th>이름</th>
                <th>생성일</th>
                <th>수정일</th>
                <th>상세보기</th>
              </tr>
            </tbody>
            <tbody>
              {userList &&
                userList.map((user, i) => {
                  return (
                    <tr key={user.id}>
                      <th>{user.id}</th>
                      <th>{user.name}</th>
                      <th>{user.createdDate}</th>
                      <th>{user.lastModifiedDate}</th>
                      <th>
                        <span className="icon">
                          <Link to={`/qazx/users/${user.id}${location.search}`}>
                            상세보기
                          </Link>
                        </span>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Pagination />
          {/*테이블*/}
          {/*버튼*/}
          <ul className="btnBox">
            <li>
              <Link to="/qazx/users/insert" className=" btnColor">
                <span>계정 추가</span>
              </Link>
            </li>
          </ul>
          {/*버튼*/}
        </div>
        {/*컨텐츠끝*/}
      </section>
    </>
  );
};

export default UserList;
