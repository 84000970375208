import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Navigation from "../navigation";
import AdminHeader from "../adminHeader";

import ArticleService from "service/article";
import BoardService from "service/board";

const ArticleList = () => {
  const { boardCode } = useParams();
  const [board, setBoard] = useState("");
  const [articleList, setArticleList] = useState([]);

  //페이징
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    //게시판 불러오기
    BoardService.getBoardByCode(boardCode).then((response) => {
      setBoard(response.data);
    });

    //게시글 목록 불러오기
    ArticleService.getArticles(boardCode, currentPage).then((response) => {
      setArticleList(response.data.content);
    });
  }, [boardCode]);

  useEffect(() => {
    console.log("board::", board);
  }, [board]);
  useEffect(() => {
    console.log("articleList::", articleList);
  }, [articleList]);
  return (
    <>
      <AdminHeader />
      <Navigation />
      <section id="section" className="adminSection">
        <div className="subTitle">
          커뮤니티 관리
          <div className="subUtilBox">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              &nbsp;&gt;&nbsp;
              <li>
                <Link to="/qazx/board">커뮤니티 관리</Link>
              </li>
              &nbsp;&gt;&nbsp;
              <li>
                <Link to={`/qazx/article/${boardCode}`}>{board.name}</Link>
              </li>
            </ul>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              <img
                src="images/printicon.png"
                width={16}
                height={16}
                alt="인쇄하기"
              />
            </Link>
          </div>
        </div>
        {/*컨텐츠시작*/}
        <div className="contents">
          <p className="tableTitle">{board.name}</p>
          {/*테이블*/}
          <table>
            <caption>게시글관리</caption>
          </table>
          <table className="tableMove">
            <tbody>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>작성일</th>
                <th>작성자</th>
                {board.useViews && <th>조회수</th>}
                <th>상세보기</th>
              </tr>
            </tbody>
            <tbody>
              {articleList.map((article) => {
                return (
                  <tr>
                    <td>{article.id}</td>

                    <td>
                      {boardCode === "qna" ? article.content : article.title}
                    </td>
                    <td>{article.createdDate}</td>
                    <td>{article.createdByName}</td>
                    {board.useViews && <td>{article.views}</td>}
                    <td>
                      <span className="icon">
                        <Link
                          to={
                            boardCode === "qna"
                              ? `/qazx/article/${boardCode}/${article.id}/detail`
                              : `/qazx/article/${boardCode}/${article.id}`
                          }
                        >
                          상세보기
                        </Link>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/*테이블*/}
          {/*버튼*/}
          <ul className="btnBox btnBoxRight">
            {boardCode !== "qna" && (
              <li>
                <Link
                  to={`/qazx/article/${board.code}/insert`}
                  className=" btnColor"
                >
                  <span>추가</span>
                </Link>
              </li>
            )}
            <li>
              <Link to={`/qazx/board`} className=" btnColor">
                <span>게시판 목록</span>
              </Link>
            </li>
          </ul>
          {/*버튼*/}
        </div>
        {/*컨텐츠끝*/}
      </section>
    </>
  );
};

export default ArticleList;
