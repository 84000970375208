import React from 'react'
import Submenu from 'component/template/submenu';

const WebService = () => {
  return (
    <>
      <section className="section">
        <div className="subVisual subVisual02">BUSINESS</div>
        <Submenu page={'business'} sub={'webService'} />
        {/*컨텐츠 시작*/}
        <div className="ai02_01">
          <div className="inner subInner">
            <div className="titleBox">
              <div className="centerBar" />
              <div className="subTitle">WEB Service</div>
            </div>
            <div className="bu_topBox">
              <p className="bu_topText delay">
                인사이트정보는 웹사이트의 컨셉 및 전략 수립부터 기획, 제작,
                프로그램 개발까지의 통합 서비스, 포괄적이고전략적인마케팅 서비스와
                유지보수까지 총괄하는 서비스를 제공하여 클라이언트의 성공적인
                E-business 수행을 위한 해결책을 제시합니다.
              </p>
              <div className="bu_topImg delay">
                <img className="imgSize01" src="images/sub/bu_02_01.png" alt="웹서비스"/>{" "}
              </div>
            </div>
          </div>
          <div className="busineesBg">
            <div className="inner subInner">
              <div className="titleBox">
                <div className="centerBar" />
                <div className="subTitle">웹 개발 프로세스</div>
              </div>
              <div className="bu_02_process">
                <ul>
                  <li>
                    <p>
                      <img src="images/sub/web01.png" alt="프로세스"/> <span>고객상담</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src="images/sub/web02.png" alt="프로세스"/>{" "}
                      <span>비즈니스 전략기획</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src="images/sub/web03.png" alt="프로세스"/>{" "}
                      <span>시스템 개발기획</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src="images/sub/web04.png" alt="프로세스"/>{" "}
                      <span>계약 사이트구현</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src="images/sub/web05.png" alt="프로세스"/>{" "}
                      <span>사이트평가</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src="images/sub/web06.png" alt="프로세스"/>{" "}
                      <span>사이트오픈</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src="images/sub/web07.png" alt="프로세스"/> <span>유지보수</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="ai01_tLTitle ai01_tLTitle03">IDENTITY</div>
              <ul className="history01Text history01Text03">
                <li>
                  인공지능이 소음을 제거하고 음성 및 특정 소리를 전달하기 위한
                  인공지능 개발을 위해 다양한 극한 소음 환경에서 수집된 대규모의
                  소음 및 음성, 소리 데이터 구축 필요
                </li>
              </ul>
              <div className="ai01_tLTitle ai01_tLTitle03">CREATIVE</div>
              <ul className="history01Text history01Text03">
                <li>
                  Creativity + Practicallity + Unity가 적절히 결합된
                  (주)인사이트만의 Creative Design은 유일하면서도 기본에 충실함이
                  기본핵심이며, 보면 볼수록 즐겁고 자부심을 느끼게 됩니다.
                </li>
              </ul>
              <div className="ai01_tLTitle ai01_tLTitle03">TREND</div>
              <ul className="history01Text history01Text03">
                <li>
                  최신의 유행을 맞춰가기 위한 무분별한 벤치마킹은 자칫 단순모방이 될
                  수 있습니다. 인사이트정보는 끊임없는 연구와 분석을 통해 독자적인
                  Design Concept을 제시하여, 이를 기반으로 구축되는 사이트
                  하나하나가 새로운 유행을 창출하고 선도할 수 있도록 합니다.
                </li>
              </ul>
              <div className="ai01_tLTitle ai01_tLTitle03">TECHNOLOGY</div>
              <ul className="history01Text history01Text03">
                <li>
                  최적의 솔루션 선택과 철저한 기술검토를 통한 안정적인 기술 적용은
                  Quality 높은 사이트 구축을 위한 열쇠입니다. 절대적인 신뢰와 높은
                  가격경쟁력을 보장합니다.
                </li>
              </ul>
              <div className="ai01_tLTitle ai01_tLTitle03">MARKETING</div>
              <ul className="history01Text history01Text03">
                <li>
                  홈페이지는 비용대비 측면에서 가장 효과적인 마케팅 방법 중
                  하나입니다. 인사이트정보가 만드는 홈페이지는 이미지 홍보를
                  뛰어넘어 Business launching을 극대화 시켜드리는 창구 역할을
                  해드립니다. 또한 컨설팅 단계부터 고객의 비젼과 장기적인 발전을
                  고려한 중장기 마케팅 플랜을 수립하게 되며, 지속적인 사후관리로
                  감동적인 마케팅 효과를 경험하시게 됩니다.
                </li>
              </ul>
            </div>
          </div>
          <div className="titleBox titleBoxMt">
            <div className="centerBar" />
            <div className="subTitle">유지보수</div>
          </div>
          <div className="inner subInner">
            <div className="busineesBgT" style={{ textAlign: "center" }}>
              유지보수의 중요성은 홈페이지운영에 있어서 커다란 영향을 미치고
              있습니다.
              <br />
              유지보수가 원활히 운영되는 홈페이지와 그렇지 않는 홈페이지의 가장큰
              차이는 운영기간동안의 접속자 수의 변화입니다.
              <br />
              오픈이후 지속적인 유지보수가 회사이미지에 중요한 영향을 행사합니다.
            </div>
            <div className="processImg">
              <img src="images/sub/web10.png" alt="유지보수"/>
              {/*ul>
                      <li><p><span>CLENT</span></p></li>
                      <li><p><span>1:1 업무대응</span></p></li>
                      <li><p><span>INSIGHT MANAGEMENT TOOL</span></p></li>
                  </ul*/}
            </div>
            <div className="bu_02_process bu_02_process3 subLastBottom">
              <ul>
                <li>
                  <p>
                    <img src="images/sub/web01.png"  alt="유지보수"/>{" "}
                    <span>
                      사이트 유지보수
                      <br />
                      의뢰
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <img src="images/sub/web02.png"  alt="유지보수"/>{" "}
                    <span>
                      사이트 요구분석 <br />
                      현황파악
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <img src="images/sub/web03.png"  alt="유지보수"/>{" "}
                    <span>
                      운영방안 제안 및<br />
                      업무량 산출
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <img src="images/sub/web08.png"  alt="유지보수"/> <span>견적제시</span>
                  </p>
                </li>
                <li>
                  <p>
                    <img src="images/sub/web06.png"  alt="유지보수"/> <span>업무시작</span>
                  </p>
                </li>
                <li>
                  <p>
                    <img src="images/sub/web09.png" alt="유지보수" />{" "}
                    <span>운영관리계약</span>
                  </p>
                </li>
                <li>
                  <p>
                    <img src="images/sub/web08.png" alt="유지보수" /> <span>견적합의</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  )
}

export default WebService;