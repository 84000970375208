import React from "react";
import { Map, MapMarker, ZoomControl } from "react-kakao-maps-sdk";

export default function KakaoMap(params) {
  return (
    <Map // 지도를 표시할 Container
      center={{
        // 지도의 중심좌표
        lat: params.lat,
        lng: params.lng,
      }}
      style={{
        // 지도의 크기
        width: "100%",
        height: "370px",
      }}
      level={2} // 지도의 확대 레벨
    >
      <MapMarker // 마커를 생성합니다
        position={{
          // 마커가 표시될 위치입니다
          lat: params.lat,
          lng: params.lng,
        }}
      />
      <ZoomControl />
    </Map>
  );
}
