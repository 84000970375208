import React from "react";
import { Link, useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

function Footer() {
  const location = useLocation();
  const path = location.pathname.includes("qazx");

  return path ? (
    <></>
  ) : (
    <footer className="footer">
      <div className="inner">
        {/* <Link to="#">개인정보처리방침</Link> */}
        <br />
        <address>
          <p>
            <span>본사(양산)</span>  |  50639 경남 양산시 동면 금오로 242 GOOD프라임빌딩 401호 / TEL : 055-372-2035 / FAX : 055-362-0099
          </p>
          <p>
            <span>서울</span>  |  06254 서울시 강남구 도곡로 123, 2층 (역삼동, 대경빌딩) / TEL : 02-568-2035
          </p>
        </address>
        <br />
        <br />
        <p className="adCopy">COPYRIGHT© 2023 BY INSIGHTINFO RIGHTS RESERVED.</p>
        <div className="familyBox">
          <div className="family">FAMILY SITE</div>
          <ul className="familyList">
            <li>
              <Link to="https://insighthub.kr/" target={"_blank"}>
                크라우드 소싱
              </Link>
            </li>
          </ul>
        </div>
        <button
          className="topBtn"
          onClick={(e) => {
            e.preventDefault();
            scroll.scrollToTop();
          }}
        >
          <img src="images/topImg.png" alt="topBtn" />
        </button>
      </div>
    </footer>
  );
}

export default Footer;
