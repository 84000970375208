import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

import BoardService from "service/board";

const Submenu = (props) => {
  const [board, setBoard] = useState([]);
  const location = useLocation();

  useEffect(() => {
    BoardService.getBoards()
      .then((response) => {
        setBoard(response.data);
      })
      .catch((error) => {
        // console.log("getBoards error::", error);
      });
  }, []);

  useEffect(() => {
    if (location.state && location.state === "scrollToSub") {
      scroller.scrollTo("sub", {
        duration: 1500,
        delay: 0,
        smooth: true,
      });
    }
  }, [location]);

  const pageSub = () => {
    switch (props.page) {
      case "company":
        return (
          <ul className="subMenu" id="sub">
            <li className={props.sub === "about" ? "subMenuChoice" : ""}>
              <Link to="/company/about" state={"scrollToSub"}>
                About Us
              </Link>
            </li>
            <li className={props.sub === "history" ? "subMenuChoice" : ""}>
              <Link to="/company/history" state={"scrollToSub"}>
                CEO &amp; History
              </Link>
            </li>
            <li className={props.sub === "location" ? "subMenuChoice" : ""}>
              <Link to="/company/location" state={"scrollToSub"}>
                오시는길
              </Link>
            </li>
          </ul>
        );
      case "business":
        return (
          <ul className="subMenu" id="sub">
            <li className={props.sub === "ai" ? "subMenuChoice" : ""}>
              <Link to="/business/aiLearningData" state={"scrollToSub"}>
                AI
              </Link>
            </li>
            <li className={props.sub === "gisService" ? "subMenuChoice" : ""}>
              <Link to="/business/gisService" state={"scrollToSub"}>
                GIS Service
              </Link>
            </li>
            <li className={props.sub === "webService" ? "subMenuChoice" : ""}>
              <Link to="/business/webService" state={"scrollToSub"}>
                WEB Service
              </Link>
            </li>
            <li
              className={props.sub === "crowdSourcing" ? "subMenuChoice" : ""}
            >
              <Link to="/business/crowdSourcing" state={"scrollToSub"}>
                CROWD Sourcing
              </Link>
            </li>
          </ul>
        );

      case "support":
        return (
          <ul className="subMenu" id="sub">
            {board &&
              board.length > 0 &&
              board
                .filter((board) => board.useYn === true)
                .map((board) => {
                  return (
                    <li
                      key={board.id}
                      className={
                        board.code === props.sub ? "subMenuChoice" : ""
                      }
                    >
                      <Link to={`/support/${board.code}`} state={"scrollToSub"}>
                        {board.name}
                      </Link>
                    </li>
                  );
                })}
          </ul>
        );
      default:
        <></>;
    }
  };

  return <>{pageSub()}</>;
};

export default Submenu;
