import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { USER_PAGE_PARAM } from "_states/page";

const Navigation = () => {
  const location = useLocation();
  const [selectMenu, setSelectMenu] = useState(null);

  /*
  useEffect(() => {
    let pathName = location.pathname;
    switch (true) {
      case /^\/board/.test(pathName): {
        setSelectMenu("board");
        
        if (/\/article/.test(pathName)) {
            setSelectSubMenu("article");
        } else if (/\/category/.test(pathName)) {
            setSelectSubMenu("category");
        } else {
            setSelectSubMenu(null);
        }
        
        break;
      }

      default: {
        setSelectMenu(null);
        //setSelectSubMenu(null);
        break;
      }
    }
  }, [location.pathname]);
  */

  return (
    <div className="leftMenu">
      <div className="logo">
        <Link to={`/`}>
          <img src="/images/logo.png" alt="로고" />
        </Link>
      </div>
      <hr className="hrLineWhite" />
      <nav className="menu">
        <Link to={`/qazx/users${USER_PAGE_PARAM}`}>
          <p>계정 관리</p>
        </Link>
        <Link to="/qazx/board" onClick={() => setSelectMenu("board")}>
          <p>커뮤니티관리</p>
        </Link>
        {/* <ul className="submenu">
          <li>
            <Link to="/qazx/board">게시판 관리</Link>
          </li>
          <li>
            <Link to="/qazx/board/article">게시글 관리</Link>
          </li>
        </ul> */}

        {/*ul class="submenu">
				<li><a href="../09/09_01manage.html" class="submenuChoice">게시판 관리</a></li>
				<li><a href="../09/09_02notice.html">공지사항</a></li>
				<li><a href="../09/09_03customer.html">묻고답하기</a></li>
				<li><a href="../09/09_04qna.html">자주하는질문</a></li>
			  </ul*/}
      </nav>
      <footer className="adminFooter">
        50524, 경상남도 양산시 중앙로 39(남부동) <br />
        대표전화 : 055-392-2114 <br />
        팩스 : 055-392-2119
      </footer>
    </div>
  );
};

export default Navigation;
