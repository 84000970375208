import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Navigation from "../navigation";
import AdminHeader from "../adminHeader";

import ArticleService from "service/article";
import BoardService from "service/board";

const ArticleDetail = () => {
  const navigate = useNavigate();

  const { id } = useParams(); //게시글ID
  const { boardCode } = useParams();

  const [article, setArticle] = useState("");
  const [board, setBoard] = useState("");

  useEffect(() => {
    //게시판 불러오기
    BoardService.getBoardByCode(boardCode).then((response) => {
      setBoard(response.data);
    });

    ArticleService.getArticle(id).then((response) => {
      setArticle(response.data);
    });
  }, []);

  const deleteBtn = (e) => {
    e.preventDefault();
    if (window.confirm("삭제하시겠습니까?")) {
      ArticleService.deleteArticle(id).then((response) => {
        if (response.status === 200) {
          alert("삭제가 완료되었습니다.");
          navigate(`/qazx/article/${boardCode}`);
        }
      });
    }
  };

  return (
    <>
      <AdminHeader />
      <Navigation />
      <section id="section" className="adminSection">
        <div className="subTitle">
          커뮤니티 관리
          <div className="subUtilBox">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              &nbsp;&gt;&nbsp;
              <li>
                <Link to="/qazx/board">커뮤니티 관리</Link>
              </li>
              &nbsp;&gt;&nbsp;
              <li>
                <Link to={`/qazx/article/${boardCode}`}>{board.name}</Link>
              </li>
            </ul>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              <img
                src="images/printicon.png"
                width={16}
                height={16}
                alt="인쇄하기"
              />
            </Link>
          </div>
        </div>
        <div className="contents">
          <p className="tableTitle">게시글 조회</p>
          <table className="TableWrite">
            <tbody>
              <tr>
                <th>번호</th>
                <td>{article.id}</td>
              </tr>
              <tr>
                <th>조회수</th>
                <td>{article.views}</td>
              </tr>
              <tr>
                <th>작성일</th>
                <td>{article.createdDate}</td>
              </tr>
              <tr>
                <th>게시판</th>
                <td>{board.name}</td>
              </tr>
              {
                /*추후 useTitle로 수정 필요*/
                board.code === "qna" ? (
                  ""
                ) : (
                  <tr>
                    <th>제목</th>
                    <td>{article.title}</td>
                  </tr>
                )
              }

              <tr>
                <th>작성자</th>
                <td>{article.createdByName}</td>
              </tr>
              {boardCode === "qna" ? (
                <>
                  <tr>
                    <th>연락처</th>
                    <td>{article.phoneNumber}</td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>{article.email}</td>
                  </tr>
                </>
              ) : (
                ""
              )}

              <tr>
                <th>내용</th>
                <td>{article.content}</td>
              </tr>
            </tbody>
          </table>
          <ul className="btnBox btnBoxRight">
            {boardCode !== "qna" && (
              <li>
                <Link to={`/qazx/article/${boardCode}/${id}/update`}>
                  <span>수정</span>
                </Link>
              </li>
            )}
            <li>
              <Link to="#" onClick={deleteBtn}>
                <span>삭제</span>
              </Link>
            </li>
            <li>
              <Link to={`/qazx/article/${boardCode}`} className=" btnColor">
                <span>목록</span>
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ArticleDetail;
