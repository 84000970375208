import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navigation from "../navigation";
import AdminHeader from "../adminHeader";

import BoardService from "service/board";

const BoardList = () => {
  const [boardList, setBoardList] = useState([]);

  useEffect(() => {
    BoardService.getBoards()
      .then((response) => {
        setBoardList(response.data);
      })
      .catch((error) => {
        //console.log("getBoards error", error);
      });
  }, []);

  return (
    <>
      <AdminHeader />
      <Navigation />
      <section id="section" className="adminSection">
        <div className="subTitle">
          커뮤니티 관리
          <div className="subUtilBox">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              &nbsp;&gt;&nbsp;
              <li>
                <Link to="/qazx/board">커뮤니티 관리</Link>
              </li>
              &nbsp;&gt;&nbsp;
              <li>
                <Link to="/qazx/board">게시판 관리</Link>
              </li>
            </ul>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              <img
                src="images/printicon.png"
                width={16}
                height={16}
                alt="인쇄하기"
              />
            </Link>
          </div>
        </div>
        {/*컨텐츠시작*/}
        <div className="contents">
          <p className="tableTitle">게시판 관리</p>
          {/*테이블*/}
          <table>
            <caption>게시판관리</caption>
          </table>
          <table className="tableMove">
            <tbody>
              <tr>
                <th>번호</th>
                <th>코드</th>
                <th>이름</th>
                <th>스킨</th>
                <th>바로가기</th>
                <th>수정</th>
              </tr>
            </tbody>
            <tbody>
              {boardList.map((board) => {
                return (
                  <tr>
                    <td>{board.id}</td>
                    <td>{board.code}</td>
                    <td>{board.name}</td>
                    <td>{board.skin}</td>
                    <td>
                      <span className="icon">
                        <Link to={`/qazx/article/${board.code}`}>바로가기</Link>
                      </span>
                    </td>
                    <td>
                      <span className="icon">
                        <Link to={`/qazx/board/${board.code}`}>수정</Link>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/*테이블*/}
          {/*버튼*/}
          <ul className="btnBox">
            <li>
              <Link to="/qazx/board/insert" className=" btnColor">
                <span>게시판 추가</span>
              </Link>
            </li>
          </ul>
          {/*버튼*/}
        </div>
        {/*컨텐츠끝*/}
      </section>
    </>
  );
};

export default BoardList;
