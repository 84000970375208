import React from 'react'
import Submenu from 'component/template/submenu';

const BoardWrite = () => {
  return (
    <>
      <section className="section">
        <div className="subVisual subVisual03">SUPPORT</div>
        <Submenu page={'support'} />
        {/*컨텐츠 시작*/}
        <div className="inner subInner">
          {/*게시판 쓰기,수정 시작*/}
          <table className="board boardWrite tableBlock">
            <tbody>
              <tr>
                <th>
                  <span>*</span>작성자
                </th>
                <td>홍길동</td>
              </tr>
              <tr>
                <th>
                  <label htmlFor="boardMail">E-mail</label>
                </th>
                <td>
                  <input type="email" id="boardMail" />
                </td>
              </tr>
              <tr>
                <th>
                  <label htmlFor="boardTitle">
                    <span>*</span>제목
                  </label>
                </th>
                <td>
                  <input type="text" id="boardTitle" />
                </td>
              </tr>
              <tr>
                <th>
                  <label htmlFor="boardWriteCon">
                    <span>*</span>내용
                  </label>
                </th>
                <td>
                  <textarea
                    rows={20}
                    cols={30}
                    id="boardWriteCon"
                    defaultValue={""}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <label htmlFor="boardWriteFile">첨부파일</label>
                </th>
                <td>
                  <p>
                    ※ 파일은 최대 10개까지 첨부하실 수 있습니다. 첨부 가능한 하나의
                    파일 사이즈는 최대 10mb입니다.
                  </p>
                  <input type="file" id="boardWriteFile" />
                </td>
              </tr>
              <tr>
                <th>
                  <label htmlFor="boardWritePassword">비밀번호</label>
                </th>
                <td>
                  <input type="password" id="boardWritePassword" />
                  <p>※ 수정/삭제시 필요하오니 반드시 기억하시기 바랍니다.</p>
                </td>
              </tr>
            </tbody>
          </table>
          {/*게시판 쓰기,수정 끝*/}
          <ul className="btnBox btnRight subLastBottom">
            <li className="btnColor">
              <a href="#">등 록</a>
            </li>
          </ul>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  )
}

export default BoardWrite;