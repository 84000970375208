import { React } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <section className="section">
      <div className="subWrap">
        <div className="container">
          <div className="errorPage">
            <div className="errorPageTxt">
              <img src="images/error.png" alt="페이지없음" />
              <div className="errorPageNum">404</div>
              <p>페이지를 찾을 수 없습니다.</p>
              <span>
                찾으시려는 페이지의 주소가 잘못 입력되었거나,
                <br />
                페이지 주소의 변경 혹은 삭제로 인해 현재 사용하실 수 없습니다.
                <br />
                입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.
              </span>
            </div>
            <div>
              <Link to="/">
                <button>이전페이지</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
