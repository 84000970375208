import React from 'react';

const loadingWrapperStyle = {
  position: "fixed",
  width: "70vw",
  height: "70vh",
  top: "0",
  left: "0",
  background: "#ffffffb7",
  zIndex: "999",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}

const loadingTextStyle = {
  textAlign: "center"
}

const Loading = () => {
  return (
    <div className="loadingWrapper" style={loadingWrapperStyle}>
      <p className="loadingText" style={loadingTextStyle}>데이터를 불러오는 중입니다.</p>
      <img src="/images/loading.gif" alt="로딩중" />
    </div>
  );
};

export default Loading;