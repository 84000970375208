import axios from "service/Axios";
import Config from "Config";

const getArticles = (boardCode, page) => {
  return axios.get(
    Config.url +
      Config.contextPath +
      "/api/article?page=" +
      page +
      "&boardCode=" +
      boardCode,

    {
      headers: { "Content-Type": `application/json` },
    }
  );
};

const getArticle = (id) => {
  return axios.get(
    Config.url + Config.contextPath + "/api/article/" + id,

    {
      headers: { "Content-Type": `application/json` },
    }
  );
};

const searchArticle = (boardCode, searchType, keyword, currentPage) => {
  return axios.get(
    Config.url +
      Config.contextPath +
      "/api/article/search?page=" +
      currentPage +
      "&boardCode=" +
      boardCode +
      "&searchType=" +
      searchType +
      "&keyword=" +
      keyword,

    {
      headers: { "Content-Type": `application/json` },
    }
  );
};

const saveArticle = (article, boardCode) => {
  return axios.post(
    Config.url +
      Config.contextPath +
      "/api/article/save?boardCode=" +
      boardCode,
    article,
    {
      headers: { "Content-Type": `application/json` },
    }
  );
};

const deleteArticle = (id) => {
  return axios.post(Config.url + Config.contextPath + "/api/article/" + id, {
    headers: { "Content-Type": `application/json` },
  });
};

const article = {
  getArticles,
  getArticle,
  searchArticle,
  saveArticle,
  deleteArticle,
};

export default article;
