import React from "react";
import Submenu from "component/template/submenu";

const GisService = () => {
  return (
    <>
      <section className="section">
        <div className="subVisual subVisual02">BUSINESS</div>
        <Submenu page={"business"} sub={"gisService"} />
        {/*컨텐츠 시작*/}
        <div className="ai02_01">
          <div className="inner subInner">
            <div className="titleBox">
              <div className="centerBar" />
              <div className="subTitle">지리정보시스템</div>
            </div>
            <div className="bu_topBox">
              <p className="bu_topText delay">
                지리정보시스템(GIS : Geographic Information System)은 컴퓨터를
                이용하여 지리정보를 관리하고 분석하는 종합정보시스템으로, 과거
                인쇄물 형태로 사용되던 지도와 지리정보를 대체하기 위해
                개발되었습니다. GIS는 지형과 관련된 모든 분야에 적용 가능하며
                지리정보를 기초로 데이터를 수집, 분석, 가공할 수 있습니다.
              </p>
              <div className="bu_topImg delay">
                <img
                  className="imgSize01"
                  src="images/sub/bu_01_01.png"
                  alt="지리정보이미지"
                />{" "}
              </div>
            </div>
            <div className="ai01_tLTitle ai01_tLTitle02">도입 효과</div>
            <ul className="history01Text history01Text02">
              <li>
                각종 수치지도정보와 시설물 정보를 유기적으로 연결하여, 공간 분석
                및 다른 시스템과의 연계를 통해 업무 효율성을 향상시킵니다.
              </li>
              <li>
                2D, 3D, 항공사진 등의 지형정보를 중첩하여 표현하여 업무의
                효율성을 높입니다.{" "}
              </li>
              <li>데이터 및 이미지를 가공하여 고부가가치 정보를 생성합니다.</li>
              <li>
                구축된 지리정보 데이터를 이용하여 업무 처리의 효율성을 높입니다.
              </li>
              <li>
                지도정보(이미지)와 생활정보(텍스트)를 결합한 생활공간정보를
                제공하여 시민들의 삶의 질을 향상시킵니다.{" "}
              </li>
              <li>
                Web GIS 엔진을 사용하여 인터넷이나 인트라넷 환경에서 다양한
                주제의 지도 서비스를 제공할 수 있습니다.
              </li>
            </ul>
          </div>
          <div className="busineesBg">
            <div className="inner subInner">
              <div className="centerBar" />
              <div className="subTitle">시스템 구축 분야</div>
              <div className="ai01_tLTitle ai01_tLTitle02">
                도로/상수도/하수도 시스템
              </div>
              <ul className="history01Text history01Text02">
                <li>
                  도로,상수도,하수도시설물을 위치정보를 이용해서 정보를
                  관리하고, 연계되는 다른 시설물들과의 공간분석을 통해 다양한
                  분야의 의사결정 및 업무효율성 증대
                </li>
              </ul>
              <ul className="bu_imgBox">
                <li>
                  <img src="images/sub/bu_01_01.jpg" alt="항공사진" />
                </li>
                <li>
                  <img src="images/sub/bu_01_02.jpg" alt="항공사진" />
                </li>
                <li>
                  <img src="images/sub/bu_01_03.jpg" alt="항공사진" />
                </li>
                <li>
                  <img src="images/sub/bu_01_04.jpg" alt="항공사진" />
                </li>
              </ul>
              <ul className="bu_imgBox">
                <li>
                  <img src="images/sub/bu_01_05.jpg" alt="항공사진" />
                </li>
                <li>
                  <img src="images/sub/bu_01_06.jpg" alt="항공사진" />
                </li>
                <li>
                  <img src="images/sub/bu_01_07.jpg" alt="항공사진" />
                </li>
                <li>
                  <img src="images/sub/bu_01_08.jpg" alt="항공사진" />
                </li>
              </ul>
              <ul className="bu_imgBox">
                <li>
                  <img src="images/sub/bu_01_09.jpg" alt="항공사진" />
                </li>
                <li>
                  <img src="images/sub/bu_01_10.jpg" alt="항공사진" />
                </li>
              </ul>
              <div className="ai01_tLTitle ai01_tLTitle02">
                항공사진/행정 시스템
              </div>
              <ul className="history01Text history01Text02">
                <li>
                  항공사진과 결합한 행정업무(환경, 교육/문화, 보건/복지/위생,
                  교통/민방위, 도시, 허가/점용, 하천, 기준점 등)의 효율성 증대.
                </li>
                <li>
                  유관기관의 지하시설물(상수,가스,전기,통신,난방,케이블)
                  위치/시설물정보 및 항공사진 제공.
                </li>
              </ul>
              <ul className="bu_imgBox">
                <li>
                  <img src="images/sub/bu_01_11.jpg" alt="도로이미지" />
                </li>
                <li>
                  <img src="images/sub/bu_01_12.jpg" alt="도로이미지" />
                </li>
                <li>
                  <img src="images/sub/bu_01_13.jpg" alt="도로이미지" />
                </li>
              </ul>
              <div className="ai01_tLTitle ai01_tLTitle02">
                생활공간정보 시스템
              </div>
              <ul className="history01Text history01Text02">
                <li>
                  정보를 시민에게 단순제공하는 것이 아닌, 행정정보와 연계한
                  지리정보를 제공하여 시민생활에 편익을 제공하고,
                  건강체육정보(등산로/산책로/공원/체육시설 등),
                  교통정보(버스노선도/자전거도로/공영주차장 등),
                  문화관광정보(축제/문화재 등),
                  부동산정보(공시지가/실거래가/부동산중개업소 등),
                  재난정보(침수피해지역/민방위대피시설/지진옥외대피소 등)를
                  지도와 함께 제공. 웰빙시대에 맞는 건강생활 지원 효과 증대와
                  대시민서비스의 질적 향상을 지원.
                </li>
              </ul>
              <ul className="bu_imgBox">
                <li>
                  <img src="images/sub/bu_01_14.jpg" alt="생활정보공간이미지" />
                </li>
                <li>
                  <img src="images/sub/bu_01_15.jpg" alt="생활정보공간이미지" />
                </li>
                <li>
                  <img src="images/sub/bu_01_16.jpg" alt="생활정보공간이미지" />
                </li>
                <li>
                  <img src="images/sub/bu_01_17.jpg" alt="생활정보공간이미지" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
};

export default GisService;
