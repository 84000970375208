import axios from "axios";
import Config from "Config";

const Api = axios.create({
  baseURL: Config.url + Config.contextPath,
  timeout: 5000,
});

Api.interceptors.request.use((config) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const accessToken = userInfo?.authentication?.grantType + " " + userInfo?.authentication?.accessToken;

  config.headers["Content-Type"] = "application/json";
  config.headers["Authorization"] = `${accessToken}`;

  return config;
});

export default Api;

// const authHeader = (url) => {
//   const accessToken = userInfo?.authentication?.accessToken;
//   const isApiUrl = url.startsWith(Config.url);

//   if (userInfo && isApiUrl) {
//     return { Authorization: userInfo?.authentication?.grantType + " " + accessToken };
//   } else {
//     return {};
//   }
// };
