import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Paging = ({
  perPage,
  totalElements,
  setCurrentPage,
  currentPage,
  suchCheck,
}) => {
  const pageNumbers = [];

  const pageLimit = 5;
  const [blockNumber, setBlockNumber] = useState(0);

  const prevPage = (e) => {
    e.preventDefault();
    setBlockNumber((n) => n - 1);
    if (blockNumber * pageLimit + pageLimit > currentPage) {
      setCurrentPage(() => blockNumber * pageLimit - 1);
    }
  };

  const nextPage = (e) => {
    e.preventDefault();
    setBlockNumber((n) => n + 1);
    if (blockNumber * pageLimit + pageLimit > currentPage) {
      setCurrentPage(() => blockNumber * pageLimit + pageLimit);
    }
  };

  for (let i = 1; i <= Math.ceil(totalElements / perPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    if (suchCheck) {
      setBlockNumber(0);
      setCurrentPage(0);
    } else if (suchCheck === undefined) {
      setBlockNumber(0);
      setCurrentPage(0);
    }
  }, [totalElements]);

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination pagination-sm justify-content-center">
        {blockNumber > 0 ? (
          <li className="page-item">
            <Link
              to="#"
              onClick={prevPage}
              className="page-link"
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
            </Link>
          </li>
        ) : null}
        {pageNumbers
          .filter(
            (pageNumber) =>
              blockNumber * pageLimit <= pageNumber - 1 &&
              pageNumber - 1 < blockNumber * pageLimit + pageLimit
          )
          .map((pageNumber) => (
            <li
              key={pageNumber}
              className="page-item"
              style={{ cursor: "pointer" }}
            >
              <a
                href="#!"
                className={`page-link ${
                  parseInt(currentPage) + 1 === pageNumber ? "currentPage" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentPage(pageNumber - 1);
                }}
              >
                {pageNumber}
              </a>
            </li>
          ))}
        {Math.ceil(totalElements / perPage) % pageLimit > 0
          ? blockNumber <
              Math.floor(Math.ceil(totalElements / perPage) / pageLimit) && (
              <li className="page-item">
                <Link
                  to="#"
                  onClick={nextPage}
                  aria-label="Next"
                  className="page-link"
                >
                  <span aria-hidden="true">&raquo;</span>
                </Link>
              </li>
            )
          : blockNumber <
              Math.floor(Math.ceil(totalElements / perPage) / pageLimit) -
                1 && (
              <li className="page-item">
                <Link
                  to="#"
                  onClick={nextPage}
                  aria-label="Next"
                  className="page-link"
                >
                  <span aria-hidden="true">&raquo;</span>
                </Link>
              </li>
            )}
      </ul>
    </nav>
  );
};

export default Paging;
