import Config from "Config";
import axios from "axios";
import Api from "utils/api";

// const userInfo = JSON.parse(localStorage.getItem("userInfo"));
// const token = userInfo?.authentication?.grantType + " " + userInfo?.authentication?.accessToken;

const login = (data) => {
  return axios.post(Config.url + Config.contextPath + "/api/login", data);
};

const findUserList = (parameters) => {
  // return axios.get(Config.url + Config.contextPath + `/api/qazx/admin/users${parameters}`, { headers: { Authorization: token } });
  return Api.get(`/api/qazx/admin/users${parameters}`);
};

const findUserById = (id) => {
  // return axios.get(Config.url + Config.contextPath + "/api/qazx/admin/" + id, { headers: { Authorization: token } });
  return Api.get("/api/qazx/admin/" + id);
};

const createUser = (data) => {
  // return axios.post(Config.url + Config.contextPath + "/api/qazx/admin/insert", data, { headers: { Authorization: token } });
  return Api.post("/api/qazx/admin/insert", data);
};

const updateUser = (id, data) => {
  // return axios.post(Config.url + Config.contextPath + "/api/qazx/admin/" + id, data, { headers: { Authorization: token } });
  return Api.post("/api/qazx/admin/" + id, data);
};

const deleteUser = (id) => {
  // return axios.post(Config.url + Config.contextPath + "/api/qazx/admin/" + id + "/delete", null, { headers: { Authorization: token } });
  return Api.post("/api/qazx/admin/" + id + "/delete", null);
};

const user = {
  login,
  findUserList,
  findUserById,
  createUser,
  updateUser,
  deleteUser,
};


export default user;