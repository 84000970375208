import React, { useState, useEffect, useRef } from "react";
import { Link as ScrollLink } from "react-scroll";

import ArticleService from "service/article";
import Carousel from "component/template/carousel";
import { Link } from "react-router-dom";

function Main() {
  const nameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const contentRef = useRef();
  const agreeRef = useRef();

  const [qna, setQna] = useState({
    createdByName: "",
    phoneNumber: "",
    email: "",
    content: "",
    agreePrivate: false,
  });

  const onSubmit = () => {
    let emailReg =
      /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
    if (qna.createdByName == null || qna.createdByName === "") {
      alert("이름을 입력해주세요.");
      return;
    }
    if (qna.phoneNumber == null || qna.phoneNumber === "") {
      alert("연락처를 입력해주세요.");
      return;
    }
    if (qna.email == null || qna.email === "") {
      alert("이메일을 입력해주세요.");
      return;
    }
    if (qna.content == null || qna.content === "") {
      alert("문의 내용을 입력해주세요.");
      return;
    }

    if (!emailReg.test(qna.email)) {
      alert("이메일 형식을 다시 확인해주세요.");
      return;
    }

    if (qna.agreePrivate == null || qna.agreePrivate === false) {
      alert("개인정보처리방침에 동의해주세요.");
      return;
    }

    if (window.confirm("문의사항을 제출하시겠습니까")) {
      ArticleService.saveArticle(qna, "qna") //setArticle(article, "boardCode")
        .then((response) => {
          if (response) {
            alert("제출이 완료되었습니다.");
            nameRef.current.value = "";
            phoneRef.current.value = "";
            emailRef.current.value = "";
            contentRef.current.value = "";
            agreeRef.current.checked = false;
            setQna({
              createdByName: "",
              phoneNumber: "",
              email: "",
              content: "",
              agreePrivate: false,
            });
          }
        })
        .catch((error) => {
          // console.log("error", error);
        });
    }
  };

  const onChangePhoneNumber = (e, name) => {
    const phoneNumber = phoneRef.current.value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

    phoneRef.current.value = phoneNumber;
    updateField(phoneNumber, name);
  };

  const updateField = (e, name) => {
    if (name === "phoneNumber") {
      setQna({
        ...qna,
        [name]: e,
      });
    } else if (name === "agreePrivate") {
      setQna({
        ...qna,
        [name]: e.target.checked,
      });
    } else {
      setQna({
        ...qna,
        [name]: e.target.value,
      });
    }
  };

  return (
    <>
      <section className="section sectionIndex" id="home">
        <div className="visualWrap">
          <div className="inner">
            <Carousel />
          </div>
        </div>
        <div className="con01" id="business">
          <div className="inner">
            <h4 className="con1Title">Business</h4>
            <div className="business">
              <div className="bsWrap">
                <Link to="/business/aiMode">
                  <div className="bsConW">
                    <div className="bsCon">
                      <div className="bsImg">
                        <img src="images/index/buIcon04.png" alt="인공지능" />
                      </div>
                      <div className="bsST">AI 인공지능</div>
                      <div className="bsT">AI 인공지능</div>
                      <div className="bsEx">
                        서비스 과정에 소비자 혹은 대중을 참여하도록 개방하여
                        생산 효율을 높입니다.
                      </div>
                      <ul className="bsEx2">
                        <li>최소 비용으로 최대 효과를 창출할 수 있는 방법</li>
                        <li>소비자들은 자신의 아이디어가 반영</li>
                        <li>창의력과전문성 제고 </li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="bsWrap">
                <Link to="/business/gisService">
                  <div className="bsConW">
                    <div className="bsCon">
                      <div className="bsImg">
                        <img src="images/index/buIcon01.png" alt="공간정보" />
                      </div>
                      <div className="bsST">공간정보</div>
                      <div className="bsT">GIS Service</div>
                      <div className="bsEx">
                        공간분석을 통하나 데이터 및 이미지의 가공을 통해서
                        고부가가치 정보를 생성합니다.
                      </div>
                      <ul className="bsEx2">
                        <li>지리정보데이터로 업무처리의 효율성을 증대</li>
                        <li>
                          생활지리정보를 제공하여 시민들의 생활의 질을 향상
                        </li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="bsWrap">
                <Link to="/business/webService">
                  <div className="bsConW">
                    <div className="bsCon">
                      <div className="bsImg">
                        <img src="images/index/buIcon02.png" alt="시스템통합" />
                      </div>
                      <div className="bsST">시스템 통합</div>
                      <div className="bsT">WEB Service</div>
                      <div className="bsEx">
                        웹 기획부터 유지보수까지 총괄하는 서비스를 제공합니다.
                      </div>
                      <ul className="bsEx2">
                        <li>사이트 요구분석 현황파악</li>
                        <li>프로젝트 설계 및 개발</li>
                        <li>전략적인 마케팅 서비스와 유지보수</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="bsWrap">
                <Link to="/business/crowdSourcing">
                  <div className="bsConW">
                    <div className="bsCon">
                      <div className="bsImg">
                        <img
                          src="images/index/buIcon03.png"
                          alt="클라우스소싱"
                        />
                      </div>
                      <div className="bsST">크라우드 소싱</div>
                      <div className="bsT">CROWD Sourcing</div>
                      <div className="bsEx">
                        서비스 과정에 소비자 혹은 대중을 참여하도록 개방하여
                        생산 효율을 높입니다.
                      </div>
                      <ul className="bsEx2">
                        <li>최소 비용으로 최대 효과를 창출할 수 있는 방법</li>
                        <li>소비자들은 자신의 아이디어가 반영</li>
                        <li>창의력과전문성 제고 </li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="inquiry" id="contact">
          <div className="inner">
            <div className="inBox">
              <div className="inBoxText">
                <p>Contact Us</p>
                <h4>
                  인사이트정보에 <br />
                  궁금한 점이 있으신가요?
                </h4>
                <span>
                  인사이트정보에 궁금한 점이 있으시다면, <br />
                  편하게 문의해주세요.{" "}
                </span>
              </div>
              <form className="inBoxForm">
                <div className="formText">
                  <div className="formImg">
                    <img src="images/index/formImg01.png" alt="이름" />
                  </div>
                  <input
                    type="text"
                    placeholder="이름"
                    onChange={(e) => updateField(e, "createdByName")}
                    ref={nameRef}
                  />
                  <p>(필수)</p>
                </div>
                <div className="formText">
                  <div className="formImg">
                    <img src="images/index/formImg02.png" alt="연락처" />
                  </div>
                  <input
                    type="text"
                    maxLength={13}
                    placeholder="연락처 (숫자만 입력해주세요.)"
                    onChange={(e) =>
                      //updateField(e, "phoneNumber")
                      onChangePhoneNumber(e, "phoneNumber")
                    }
                    ref={phoneRef}
                  />
                  <p>(필수)</p>
                </div>
                <div className="formText">
                  <div className="formImg">
                    <img src="images/index/formImg03.png" alt="이메일" />
                  </div>
                  <input
                    type="text"
                    placeholder="이메일"
                    onChange={(e) => updateField(e, "email")}
                    ref={emailRef}
                  />
                  <p>(필수)</p>
                </div>
                <div className="formText" style={{ marginBottom: ".5em" }}>
                  <div className="formImg">
                    <img src="images/index/formImg04.png" alt="문의내용" />
                  </div>
                  <textarea
                    placeholder="문의내용"
                    defaultValue={""}
                    onChange={(e) => updateField(e, "content")}
                    ref={contentRef}
                  />
                </div>
                <div className="formBtnBox">
                  <div className="formAgree">
                    <input
                      type="checkbox"
                      id="ccBox"
                      onChange={(e) => updateField(e, "agreePrivate")}
                      ref={agreeRef}
                    />
                    <label htmlFor="ccBox">
                      &nbsp;&nbsp;개인정보처리방침&nbsp;&nbsp;동의
                    </label>
                  </div>
                  <button type="button" className="formBtn" onClick={onSubmit}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="inquiryBg" />
        </div>
      </section>
      <aside id="quickWrap">
        <ul>
          <li>
            <ScrollLink
              to="home"
              spy={true}
              smooth={true}
              style={{ cursor: "pointer" }}
            >
              HOME
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="business"
              spy={true}
              smooth={true}
              style={{ cursor: "pointer" }}
            >
              BUSINESS
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="contact"
              spy={true}
              smooth={true}
              style={{ cursor: "pointer" }}
            >
              CONTACT US
            </ScrollLink>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Main;
