import React, { useRef } from "react";
import Submenu from "component/template/submenu";
import HistoryList from "./historyList";
import { useRecoilState } from "recoil";
import { historyState } from "_states/atom";
import { Link } from "react-router-dom";

const History = () => {
  const [history, setHistory] = useRecoilState(historyState);
  const scrollRef = useRef();

  return (
    <>
      <section className="section">
        <div className="subVisual subVisual01">COMPANY</div>
        <Submenu page={"company"} sub={"history"} />
        {/*컨텐츠 시작*/}
        <div className="aboutCeo">
          <div className="inner subInner aboutCeoInner">
            <div className="aboutCeoTitle">
              <p>변화와 혁신을</p>
              <p>두려워 하지 않는 기업</p>
            </div>
            <div className="aboutCeoBar" />
            <div className="aboutCeoText">
              다양하고 급변하는 변화의 물결 속에서 넘쳐나는 정보를 바탕으로 새로운 것을 만들어가는 <br />
              디지털중심의 세상으로 변화하고 있습니다. 인사이트정보는 사람 중심의 경영을 추구합니다. <br />
              사물이 아닌 사람이 만들어가는 세상을 꿈꾸고 있습니다.
              <br />
              추구하는 목표가 같은 그리고, 그 목표를 위해 모두가 한마음으로 나아갈 수 있는 기업이 되고자 합니다.
              <br />
              “창의와 혁신, 도전과 열린 마음” 을 가진 사람 중심의 기업이 되겠습니다.
              <br />
              감사합니다.
            </div>
            <div className="aboutCeoSign">
              <p>인사이트정보 대표이사</p>
              <div className="aboutCeoSignImg">
                <span>김태기</span>
                <img src="images/sub/about_ceoSign.png" alt="사인" />
              </div>
            </div>
          </div>
        </div>
        <div className="inner subInner">
          <div className="historyBox">
            <div className="centerBar" />
            <div className="subTitle">연혁</div>
            <ul className="historyList">
              <li className={history === "재도약기" ? "historyChoice" : ""}>
                <Link
                  to="#"
                  onClick={(e) => {
                    setHistory("재도약기");
                  }}
                  state={"scrollToHis"}
                >
                  재도약기
                </Link>
              </li>
              <li className={history === "안정기" ? "historyChoice" : ""}>
                <Link
                  to="#"
                  onClick={(e) => {
                    setHistory("안정기");
                  }}
                  state={"scrollToHis"}
                >
                  안정기
                </Link>
              </li>
              <li className={history === "성장기" ? "historyChoice" : ""}>
                <Link
                  to="#"
                  onClick={(e) => {
                    setHistory("성장기");
                  }}
                  state={"scrollToHis"}
                >
                  성장기
                </Link>
              </li>
              <li className={history === "진입기" ? "historyChoice" : ""}>
                <Link
                  to="#"
                  onClick={(e) => {
                    setHistory("진입기");
                  }}
                  state={"scrollToHis"}
                >
                  진입기
                </Link>
              </li>
            </ul>
            <div
              className="historyListImg"
              ref={(ref) => {
                scrollRef.current = ref;
              }}
            >
              <div className="hsL historyListImg01">
                <p>{history}</p>
              </div>
            </div>
            <HistoryList />
          </div>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
};

export default History;
