import React, { useEffect, useState } from "react";
import AdminHeader from "../adminHeader";
import Navigation from "../navigation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserService from "service/user.js";
import { useForm } from "react-hook-form";
import { USER_PAGE_PARAM } from "_states/page";

const UserInsertOrUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [requestErrMsg, setRequestErrMsg] = useState([]);
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const onSubmit = (data) => {
    if (state) {
      return updateUser(data);
    } else return createUser(data);
  };

  const createUser = (data) => {
    UserService.createUser(data)
      .then((response) => {
        if (response.status === 200) {
          navigate(`/qazx/users${USER_PAGE_PARAM}`);
        }
      })
      .catch((err) => {
        // console.log(err.response);
        if (err.response.status === 401) {
          setRequestErrMsg(err.response.data);
        }
      });
  };

  const updateUser = (data) => {
    // console.log("update", state.id, data);
    UserService.updateUser(state.id, data).then((response) => {
      if (response.status === 200) {
        navigate(`/qazx/users/${state.id}${location.search}`);
      }
    });
  };

  useEffect(() => {
    if (state) {
      Object.entries(state).map((element) => {
        setValue(element[0], element[1]);
      });
    }
  }, []);

  return (
    <>
      <AdminHeader />
      <Navigation />
      <section id="section" className="adminSection">
        <div className="subTitle">
          {state ? "계정 수정" : "계정 추가"}
          <div className="subUtilBox">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>{" "}
              &gt;{" "}
              <li>
                <a href="#">계정관리</a>
              </li>
              &gt;{" "}
              <li>
                <a href="#">{state ? "계정수정" : "계정추가"}</a>
              </li>
            </ul>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              <img
                src="images/printicon.png"
                width={16}
                height={16}
                alt="인쇄하기"
              />
            </Link>
          </div>
        </div>
        {/*컨텐츠시작*/}
        <div className="contents">
          {/*테이블*/}
          <form onSubmit={handleSubmit(onSubmit)}>
            <table className="TableWrite">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>
                    <input
                      type="text"
                      id="id"
                      maxLength="12"
                      {...register("id", {
                        required: true,
                        minLength: {
                          message: "4글자 이상 입력하세요",
                          value: 4,
                        },
                        maxLength: {
                          message: "아이디는 최대 12자까지 가능합니다.",
                          value: 12,
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9]*$/,
                          message: "아이디는 영어와 숫자만 사용 가능합니다.",
                        },
                      })}
                      disabled={state ? true : false}
                    />
                    <span className="imporText">
                      {errors.id && errors.id.message}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor="password">비밀번호</label>
                  </th>
                  <td>
                    <input
                      type="password"
                      id="password"
                      maxLength="16"
                      {...register("password", {
                        required: state ? false : true,
                        minLength: { message: "4자 이상 입력하세요", value: 4 },
                        maxLength: {
                          message: "비밀번호는 최대 16자까지 가능합니다.",
                          value: 16,
                        },
                      })}
                    />
                    <span className="imporText">
                      {" "}
                      {errors.password && errors.password.message}
                    </span>
                    {state ? <p> 비밀번호 입력 시 수정됩니다. </p> : null}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor="name">이름</label>
                  </th>
                  <td>
                    <input
                      type="text"
                      id="name"
                      maxLength="10"
                      {...register("name", {
                        required: true,
                        minLength: {
                          message: "2글자 이상 입력하세요",
                          value: 2,
                        },
                        maxLength: {
                          message: "이름은 최대 10자까지 가능합니다.",
                          value: 10,
                        },
                        pattern: {
                          value: /^[가-힣]*$/,
                          message: "이름은 한글만 사용 가능합니다.",
                        },
                      })}
                    />
                    <span className="imporText">
                      {" "}
                      {errors.name && errors.name.message}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor="phone">연락처</label>
                  </th>
                  <td>
                    <input
                      type="text"
                      id="phoneNumber"
                      maxLength="12"
                      {...register("phoneNumber", {
                        required: true,
                        pattern: {
                          value:
                            /^(01([0|1|6|7|8|9]{1}))-([0-9]{3,4})-([0-9]{4})$/,
                          message: "휴대폰 번호를 올바르게 입력해주세요.",
                        },
                      })}
                      onChange={(e) => {
                        setValue(
                          "phoneNumber",
                          e.target.value
                            .replace(/[^0-9]/g, "")
                            .replace(/^(\d{3})(\d{4})(\d{4})$/, "$1-$2-$3")
                            .replace("--", "-")
                        );
                      }}
                      placeholder="숫자만 입력해주세요."
                    />
                    <span className="imporText">
                      {errors.phoneNumber && errors.phoneNumber.message}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            {/*테이블*/}
            {/*버튼*/}
            <p className="imporText">
              {requestErrMsg && requestErrMsg.Message}
            </p>
            <ul className="btnBox">
              <li>
                <button type="submit">
                  <a className="btnColor">{state ? "수정" : "등록"}</a>
                </button>
              </li>
              <li>
                <Link
                  to={
                    state
                      ? `/qazx/users/${state.id}${location.search}`
                      : `/qazx/users${USER_PAGE_PARAM}`
                  }
                >
                  <span>취소</span>
                </Link>
              </li>
            </ul>
          </form>
          {/*버튼*/}
        </div>
        {/*컨텐츠끝*/}
      </section>
    </>
  );
};

export default UserInsertOrUpdate;
