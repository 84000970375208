import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import Navigation from "../navigation";
import AdminHeader from "../adminHeader";

import BoardService from "service/board";

const BoardInsertOrUpdate = () => {
  const { boardCode } = useParams(); //insert 또는 boardCode
  const navigate = useNavigate();

  const [board, setBoard] = useState("");

  const skinRef = useRef("");
  const useYnRef = useRef(false);
  const fileRef = useRef(false);
  const viewsRef = useRef(false);
  const newRef = useRef(false);

  useEffect(() => {
    if (boardCode !== "insert") {
      //게시판 수정
      BoardService.getBoardByCode(boardCode).then((response) => {
        setBoard(response.data);
      });
    } else {
      //게시판 추가
      setBoard({
        id: "",
        code: "",
        name: "",
        skin: "notice",
        useYn: false,
        useFile: false,
        useViews: false,
        useNew: false,
      });
    }
  }, [boardCode]);

  useEffect(() => {
    skinRef.current.value = board.skin;
    useYnRef.current.checked = board.useYn;
    fileRef.current.checked = board.useFile;
    viewsRef.current.checked = board.useViews;
    newRef.current.checked = board.useNew;
  }, [board]);

  const updateField = (e, name) => {
    if (
      name === "useFile" ||
      name === "useViews" ||
      name === "useNew" ||
      name === "useYn"
    ) {
      setBoard({
        ...board,
        [name]: e.target.checked,
      });
    } else {
      setBoard({
        ...board,
        [name]: e.target.value,
      });
    }
  };

  const submit = () => {
    if (board.code == null || board.code === "") {
      alert("게시판 코드를 입력해주세요.");
      return;
    }
    if (board.name == null || board.name === "") {
      alert("게시판 이름을 입력해주세요.");
      return;
    }
    if (window.confirm("저장하시겠습니까?")) {
      BoardService.saveBoard(board).then((response) => {
        if (response.status === 200) {
          alert("저장이 완료되었습니다.");
          navigate("/qazx/board");
        }
      });
    }
  };

  //게시판 삭제 //비활성화
  const deleteBtn = (e) => {
    e.preventDefault();
    if (window.confirm("삭제하시겠습니까?")) {
      BoardService.deleteBoard(board.id).then((response) => {
        if (response.status === 200) {
          alert("삭제가 완료되었습니다.");
          navigate("/qazx/board/");
        }
      });
    }
  };

  return (
    <>
      <AdminHeader />
      <Navigation />
      <section id="section" className="adminSection">
        <div className="subTitle">
          게시판 관리
          <div className="subUtilBox">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              &nbsp;&gt;&nbsp;
              <li>
                <Link to="/qazx/board">커뮤니티 관리</Link>
              </li>
              &nbsp;&gt;&nbsp;
              <li>
                <Link to="/qazx/board">게시판 관리</Link>
              </li>
            </ul>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              <img
                src="images/printicon.png"
                width={16}
                height={16}
                alt="인쇄하기"
              />
            </Link>
          </div>
        </div>
        {/*컨텐츠시작*/}
        <div className="contents">
          <p className="tableTitle">게시판 추가/수정</p>
          <table className="TableWrite">
            <tbody>
              {boardCode !== "insert" && (
                <tr>
                  <th>번호</th>
                  <td>
                    <input value={board.id} disabled />
                  </td>
                </tr>
              )}
              <tr>
                <th>코드</th>
                <td>
                  <input
                    defaultValue={board.code}
                    onChange={(e) => updateField(e, "code")}
                  />
                </td>
              </tr>
              <tr>
                <th>이름</th>
                <td>
                  <input
                    defaultValue={board.name}
                    onChange={(e) => updateField(e, "name")}
                  />
                </td>
              </tr>
              <tr>
                <th>스킨</th>
                <td>
                  <select
                    ref={skinRef}
                    onChange={(e) => updateField(e, "skin")}
                  >
                    <option value="notice">notice</option>
                    <option value="faq">faq</option>
                    <option value="qna">qna</option>
                    <option value="gallery">gallery</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>사용 여부</th>
                <td>
                  <input
                    id="useYn"
                    className="checkbox"
                    type="checkbox"
                    ref={useYnRef}
                    onChange={(e) => updateField(e, "useYn")}
                  />
                  <label for="useYn">사용</label>
                </td>
              </tr>
              <tr>
                <th>파일 업로드</th>
                <td>
                  <input
                    id="useFile"
                    className="checkbox"
                    type="checkbox"
                    ref={fileRef}
                    onChange={(e) => updateField(e, "useFile")}
                  />
                  <label for="useFile">사용</label>
                </td>
              </tr>
              <tr>
                <th>조회수</th>
                <td>
                  <input
                    id="useViews"
                    className="checkbox"
                    type="checkbox"
                    ref={viewsRef}
                    onChange={(e) => updateField(e, "useViews")}
                  />
                  <label for="useViews">사용</label>
                </td>
              </tr>
              <tr>
                <th>새글 알림</th>
                <td>
                  <input
                    id="useNew"
                    className="checkbox"
                    type="checkbox"
                    ref={newRef}
                    onChange={(e) => updateField(e, "useNew")}
                  />
                  <label for="useNew">사용</label>
                </td>
              </tr>
            </tbody>
          </table>
          {/*버튼*/}
          <ul className="btnBox btnBoxRight">
            <li>
              <Link to="#" className=" btnColor" onClick={submit}>
                <span>저장</span>
              </Link>
            </li>
            <li>
              <Link to={"/qazx/board"}>
                <span>취소</span>
              </Link>
            </li>
            {boardCode !== "insert" && (
              <li style={{ display: "none" }}>
                <Link to="#" /*onClick={deleteBtn}*/>
                  <span>삭제</span>
                </Link>
              </li>
            )}
          </ul>
          {/*버튼*/}
        </div>
        {/*컨텐츠끝*/}
      </section>
    </>
  );
};

export default BoardInsertOrUpdate;
