import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

import Navigation from "../navigation";
import AdminHeader from "../adminHeader";

import ArticleService from "service/article";
import BoardService from "service/board";

const ArticleInsertOrUpdate = () => {
  const navigate = useNavigate();

  const { id } = useParams(); //게시글ID
  const { boardCode } = useParams();

  const [boardList, setBoardList] = useState([]);
  const [article, setArticle] = useState({
    id: "",
    boardCode: boardCode,
    title: "",
    createdDate: "",
    createdByName: "",
    content: "",
  });
  const [board, setBoard] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    //게시판 불러오기
    BoardService.getBoards().then((response) => {
      setBoardList(response.data);
    });
    BoardService.getBoardByCode(boardCode).then((response) => {
      setBoard(response.data);
    });

    //게시글 불러오기
    if (id !== undefined) {
      ArticleService.getArticle(id).then((response) => {
        let _article = response.data;
        _article.boardCode = _article.board.code;
        setArticle(_article);
        setContent(_article.content);
      });
    }
  }, [id]);

  const updateContent = (e) => {
    setContent(e);
  };

  const updateField = (e, name) => {
    setArticle({
      ...article,
      [name]: e.target.value,
    });
  };

  const submit = (e) => {
    e.preventDefault();
    article.content = content;

    if (window.confirm("저장하시겠습니까?")) {
      ArticleService.saveArticle(article, article.boardCode).then(
        (response) => {
          if (response.status === 200) {
            alert("저장이 완료되었습니다.");
            navigate(`/qazx/article/${boardCode}`);
          }
        }
      );
    }
  };

  const deleteBtn = (e) => {
    e.preventDefault();
    if (window.confirm("삭제하시겠습니까?")) {
      ArticleService.deleteArticle(id).then((response) => {
        if (response.status === 200) {
          alert("삭제가 완료되었습니다.");
          navigate(`/qazx/article/${boardCode}`);
        }
      });
    }
  };
  return (
    <>
      <AdminHeader />
      <Navigation />
      <section id="section" className="adminSection">
        <div className="subTitle">
          커뮤니티 관리
          <div className="subUtilBox">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              &nbsp;&gt;&nbsp;
              <li>
                <Link to="/qazx/board">커뮤니티 관리</Link>
              </li>
              &nbsp;&gt;&nbsp;
              <li>
                <Link to={`/qazx/article/${boardCode}`}>{board.name}</Link>
              </li>
            </ul>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              <img
                src="images/printicon.png"
                width={16}
                height={16}
                alt="인쇄하기"
              />
            </Link>
          </div>
        </div>
        {/*컨텐츠시작*/}
        <div className="contents">
          <p className="tableTitle">{id ? "게시글 수정" : "게시글 등록"}</p>
          <table className="TableWrite">
            <tbody>
              {article.id && (
                <>
                  <tr>
                    <th>번호</th>
                    <td>
                      <input defaultValue={article.id} disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>조회수</th>
                    <td>
                      <input defaultValue={article.views} disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>작성일</th>
                    <td>
                      <input defaultValue={article.createdDate} disabled />
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <th>게시판</th>
                <td>
                  <select
                    value={article.boardCode}
                    onChange={(e) => updateField(e, "boardCode")}
                    disabled
                  >
                    {boardList.map((board) => (
                      <option value={board.code}>{board.name}</option>
                    ))}
                    {/* {boardCode === "qna"
                      ? boardList
                          .filter((board) => board.code === "qna")
                          .map((board) => (
                            <option value={board.code}>{board.name}</option>
                          ))
                      : boardList
                          .filter((board) => board.code !== "qna")
                          .map((board) => (
                            <option value={board.code}>{board.name}</option>
                          ))} */}
                  </select>
                </td>
              </tr>
              {
                /*추후 useTitle로 수정 필요*/
                boardCode === "qna" ? (
                  ""
                ) : (
                  <tr>
                    <th>제목</th>
                    <td>
                      <input
                        defaultValue={article && article.title}
                        onChange={(e) => updateField(e, "title")}
                      />
                    </td>
                  </tr>
                )
              }

              {boardCode === "qna" ? (
                <>
                  <tr>
                    <th>작성자</th>
                    <td>
                      <input defaultValue={article.createdByName} disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>연락처</th>
                    <td>
                      <input defaultValue={article.phoneNumber} disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>
                      <input defaultValue={article.email} disabled />
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <th>작성자</th>
                  <td>
                    <input
                      defaultValue={article && article.createdByName}
                      onChange={(e) => updateField(e, "createdByName")}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <th>내용</th>
                <td>
                  <Editor
                    id="content"
                    name="content"
                    apiKey="soljbx345rffw1b0w8cewcynk3cdj6wdx073iwomcjxipkcj"
                    initialValue={article && article.content}
                    init={{
                      language: "ko_KR",
                      branding: false, //하단 브랜딩 제거
                      resize: false, // 하단 리사이즈 제거
                      elementpath: false, //상태 표시줄 제거
                    }}
                    onEditorChange={updateContent}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {/*버튼*/}
          <ul className="btnBox btnBoxRight">
            <li>
              <Link to="#" className=" btnColor" onClick={submit}>
                <span>저장</span>
              </Link>
            </li>
            <li>
              <Link to={`/qazx/article/${boardCode}`}>
                <span>취소</span>
              </Link>
            </li>
            {article.id && (
              <li>
                <Link to="#" onClick={deleteBtn}>
                  <span>삭제</span>
                </Link>
              </li>
            )}
          </ul>
          {/*버튼*/}
        </div>
        {/*컨텐츠끝*/}
      </section>
    </>
  );
};

export default ArticleInsertOrUpdate;
