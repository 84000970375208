import React, { useEffect, useState } from "react";
import AdminHeader from "../adminHeader";
import Navigation from "../navigation";
import UserService from "service/user";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const UserDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userDetail, setUserDetail] = useState([]);
  const param = useParams();
  const { id } = param;

  const deleteUser = (e) => {
    e.preventDefault();
    // console.log("삭제id :", id);
    if (window.confirm("삭제하시겠습니까?")) {
      UserService.deleteUser(id).then((response) => {
        if (response.status === 200) {
          navigate(`/qazx/users${location.search}`);
        }
      });
    } else {
      return;
    }
  };

  useEffect(() => {
    UserService.findUserById(id).then((response) => {
      // console.log(response.data);
      if (response.status === 200) {
        setUserDetail(response.data);
      }
    });
  }, []);

  return (
    <>
      <AdminHeader />
      <Navigation />
      <section id="section" className="adminSection">
        <div className="subTitle">
          계정 상세정보
          <div className="subUtilBox">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>{" "}
              &gt;{" "}
              <li>
                <a href="#">계정관리</a>
              </li>
              &gt;{" "}
              <li>
                <a href="#">상세보기</a>
              </li>
            </ul>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              <img
                src="images/printicon.png"
                width={16}
                height={16}
                alt="인쇄하기"
              />
            </Link>
          </div>
        </div>
        {/*컨텐츠시작*/}
        <div className="contents">
          {/*배너/팝업 관리*/}
          {/*테이블*/}
          <table className="TableWrite">
            <caption>계정 상세정보</caption>
            <colgroup>
              <col width="20%" />
              <col width="80%" />
            </colgroup>
            <tbody>
              <tr>
                <th>아이디</th>
                <td>{userDetail.id}</td>
              </tr>
              <tr>
                <th>권한</th>
                <td>{userDetail.authority}</td>
              </tr>
              <tr>
                <th>생성일</th>
                <td>{userDetail.createdDate}</td>
              </tr>
              <tr>
                <th>수정일</th>
                <td>{userDetail.lastModifiedDate}</td>
              </tr>
              <tr>
                <th>이름</th>
                <td>{userDetail.name}</td>
              </tr>
              <tr>
                <th>연락처</th>
                <td>{userDetail.phoneNumber}</td>
              </tr>
            </tbody>
          </table>
          {/*테이블*/}
          {/*공지사항 글쓰기*/}
          {/*버튼*/}
          <ul className="btnBox">
            <li>
              <Link to={`/qazx/users${location.search}`} className="btnColor">
                <span>목록</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/qazx/users/${userDetail.id}/update${location.search}`}
                state={userDetail}
              >
                <span>수정</span>
              </Link>
            </li>
            <li>
              <a onClick={(e) => deleteUser(e)}>
                <span>삭제</span>
              </a>
            </li>
          </ul>
          {/*버튼*/}
        </div>
        {/*컨텐츠끝*/}
      </section>
    </>
  );
};

export default UserDetail;
