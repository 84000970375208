import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { historyState } from "_states/atom";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

const HistoryList = () => {
  const [history, setHistory] = useRecoilState(historyState);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state === "scrollToHis") {
      scroller.scrollTo("hst", {
        duration: 1500,
        offset: -150,
        delay: 0,
        smooth: true,
      });
    }
  }, [history]);

  const setHistoryText = () => {
    switch (history) {
      case "재도약기":
        return (
          <div className="historyListText" id="hst">
            <div className="hsT">
              <span>INSIGHT INFO </span>
              <p>2019 ~ 현재</p>
            </div>
            <div className="hsTCon">
              <div className="hsT2 hsT2Top">
                <div className="hsT2Year">2023</div>
                <div className="hsT2Month">
                  <ul>
                    <li>경로당 복지시스템 고도화 구축 </li>
                  </ul>
                  <ul>
                    <li>양산시, 양산시시설관리공단 홈페이지 클라우드 전환사업</li>
                  </ul>
                  <ul>
                    <li>베트남 언어 원천의 말뭉치 데이터 구축(NIA)</li>
                  </ul>
                  <ul>
                    <li>다양한 소음원에서의 Ground Truth 지식정보데이터 구축(NIA)</li>
                  </ul>
                  <ul>
                    <li>얼굴 사진 실감 가시화용 AI 학습용데이터 구축(ETRI)</li>
                  </ul>
                  <ul>
                    <li>북한 인물정보 AI 학습용데이터(ADD)</li>
                  </ul>
                  <ul>
                    <li>양산시 토목뱅크시스템 고도화</li>
                  </ul>
                  <ul>
                    <li>양산시 경로당복지 헬스케어시스템 고도화</li>
                  </ul>
                  <ul>
                    <li>진주시 하수처리공정 수질검사관리시스템 구축</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2022</div>
                <div className="hsT2Month">
                  <ul>
                    <li>NIA AI허브에 크라우드소싱 플랫폼 인사이트허브 등록</li>
                  </ul>
                  <ul>
                    <li>밀양시시설관리공단 정보시스템 고도화</li>
                  </ul>
                  <ul>
                    <li>사천시 긴급재난지원금 지원시스템 구축</li>
                  </ul>
                  <ul>
                    <li>지방세 무인수납시스템(KIOSK) 구축</li>
                  </ul>
                  <ul>
                    <li>극한 소음환경에서의 대화 인공지능 학습용 데이터 구축(NIA)</li>
                  </ul>
                  <ul>
                    <li>소음환경에서의 소리데이터 인공지능 학습용 데이터 구축(NIA)</li>
                  </ul>
                  <ul>
                    <li>연령대별 특징적 발화 인공지능 학습용 데이터 구축(NIA)</li>
                  </ul>
                  <ul>
                    <li>제61회 경남도민체육대회 누리집(홈페이지) 구축</li>
                  </ul>
                  <ul>
                    <li>양산시 긴급재난지원금 지원시스템 구축</li>
                  </ul>
                  <ul>
                    <li>사물주소를 활용한 안내 시스템 구축 </li>
                  </ul>
                  <ul>
                    <li>대운산 생태휴양밸리 통합홈페이지 구축</li>
                  </ul>
                  <ul>
                    <li>양산시시설관리공단 대표홈페이지 개편</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2021</div>
                <div className="hsT2Month">
                  <ul>
                    <li>양산시 도시생태현황지도 관리시스템 구축</li>
                  </ul>
                  <ul>
                    <li>열악한 소음환경에서 대화 인공지능 학습용 데이터 구축(NIA)</li>
                  </ul>
                  <ul>
                    <li>양산시 통합대관시스템 구축</li>
                  </ul>
                  <ul>
                    <li>도시정보시스템 웹표준 고도화 사업</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2020</div>
                <div className="hsT2Month">
                  <ul>
                    <li>NIA 환경오염 산업페기물 이미지 AI 인공지능 인식 데이터 구축</li>
                  </ul>
                  <ul>
                    <li>아동보호관리시스템 고도화(부산시-인프라닉스)</li>
                  </ul>
                  <ul>
                    <li>수변공원 체육시설물 예약시스템 구축 용역(양산시)</li>
                  </ul>
                  <ul>
                    <li>통합유지보수홈페이지 고도화 구축(부산시-인프라닉스)</li>
                  </ul>
                  <ul>
                    <li>행정정보시스템 통합유지보수 용역 사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>시설관리공단 홈페이지 유지보수 용역 (양산시시설관리공단)</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2019</div>
                <div className="hsT2Month">
                  <ul>
                    <li>전산SM(정보시스템 운영·유지보수) 용역(한국자산관리공사-루트온플러스)</li>
                  </ul>
                  <ul>
                    <li>건설정보관리사업(2단계) 구축 용역(부산시)</li>
                  </ul>
                  <ul>
                    <li>공원시설 사용예약 시스템 구축(양산시)</li>
                  </ul>
                  <ul>
                    <li>문화예술회관(복합문화타운) 홈페이지 개편 (양산시시설관리공단)</li>
                  </ul>
                  <ul>
                    <li>수질검사관리시스템 고도화 구축 용역(양산시-신원정보통신)</li>
                  </ul>
                  <ul>
                    <li>시설관리공단 홈페이지 유지보수 용역 (양산시시설관리공단)</li>
                  </ul>
                  <ul>
                    <li>숲애서 홈페이지 구축 용역(양산시)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      case "안정기":
        return (
          <div className="historyListText" id="hst">
            <div className="hsT">
              <span>INSIGHT INFO </span>
              <p>2015 ~ 2018</p>
            </div>
            <div className="hsTCon">
              <div className="hsT2 hsT2Top">
                <div className="hsT2Year">2018</div>
                <div className="hsT2Month">
                  <ul>
                    <li>부산시 데이터센터 자원 통합유지보수 (부산시-콤텍정보통신)</li>
                  </ul>
                  <ul>
                    <li>건설정보관리 기본계획 수립 및 정보관리사업(1단계) (부산시-우리아이티)</li>
                  </ul>
                  <ul>
                    <li>국가 건물에너지통합관리시스템 운영관리 (한국감정원-네오위드넷)</li>
                  </ul>
                  <ul>
                    <li>도시정보시스템 고도화 사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>공간정보시스템 고도화 (양산시)</li>
                  </ul>
                  <ul>
                    <li>시정빨래터 시스템 고도화 구축 (양산시)</li>
                  </ul>
                  <ul>
                    <li>문화스포츠센터 반응형 홈페이지 개편 (양산시시설관리공단)</li>
                  </ul>
                  <ul>
                    <li>행정정보시스템 통합유지보수 용역 사업 (양산시-앤시정보기술)</li>
                  </ul>
                  <ul>
                    <li>홈페이지 통합유지보수 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>시설관리공단 홈페이지 유지보수 용역 (양산시시설관리공단)</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2017</div>
                <div className="hsT2Month">
                  <ul>
                    <li>영상반출시스템 SDK연동 개발 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>부산시 데이터센터 자원 통합유지보수 (부산시-콤텍정보통신)</li>
                  </ul>
                  <ul>
                    <li>CCTV 실시간 상황관제 빅데이터시스템 구축 (양산시)</li>
                  </ul>
                  <ul>
                    <li>정책지도시스템 구축 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>농산물유통정보시스템 구축 용역 (양산시농업기술센터)</li>
                  </ul>
                  <ul>
                    <li>국가 건물에너지통합관리시스템 운영관리 (한국감정원-네오위드넷)</li>
                  </ul>
                  <ul>
                    <li>삼성중공업 블록관리시스템 개발 (삼성중공업-유니정보)</li>
                  </ul>
                  <ul>
                    <li>공동주.공중선 관리 전산시스템 구축 용역 (부산시-고산자)</li>
                  </ul>
                  <ul>
                    <li>도시계획시설사업 빅데이터 구축 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>UAV 영상 後 처리시스템 구축 (한국자산관리공사-에스엔씨)</li>
                  </ul>
                  <ul>
                    <li>홈페이지 통합유지보수 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>시설관리공단 홈페이지 유지보수 용역 (양산시시설관리공단)</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2016</div>
                <div className="hsT2Month">
                  <ul>
                    <li>UAV 영상 後 처리시스템 구축 (한국자산관리공사-에스엔씨)</li>
                  </ul>
                  <ul>
                    <li>09월 토목뱅크시스템 구축 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>통합성과관리시스템 기능개선 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>빅데이터 연계 인터페이스를 위한 서버개발 (해양조사원-올포랜드)</li>
                  </ul>
                  <ul>
                    <li>시.도 정보통신공사시스템 유지보수 (부산시-콤텍정보통신)</li>
                  </ul>
                  <ul>
                    <li>도시공간정보시스템 고도화 사업 (부산시-중앙항업)</li>
                  </ul>
                  <ul>
                    <li>국가 건물에너지통합관리시스템 운영관리 (한국감정원-유알피시스템)</li>
                  </ul>
                  <ul>
                    <li>도시정보시스템 고도화 사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>행정정보시스템 통합유지보수 용역 사업 (양산시)</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2015</div>
                <div className="hsT2Month">
                  <ul>
                    <li>황산문화체육공원 홈페이지 및 예약시스템 구축 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>통합행정정보시스템 저장장비 가상화시스템 구축 (양산시)</li>
                  </ul>
                  <ul>
                    <li>위치기반 행정정보 개방 지원 ISP (행자부-아이씨티웨이)</li>
                  </ul>
                  <ul>
                    <li>3차원 공간정보시스템 대시민 기능개선 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>국가 건물에너지통합관리시스템 운영관리 (한국감정원-솔리데오시스템즈)</li>
                  </ul>
                  <ul>
                    <li>통합문화스포츠센터 대관시스템 개발 용역 (양산시시설관리공단)</li>
                  </ul>
                  <ul>
                    <li>국가 건물에너지통합관리시스템 구축사업(3차) 용역 (국토부-올포랜드)</li>
                  </ul>
                  <ul>
                    <li>행정정보시스템 통합유지보수(UIS) 용역 (양산시-LG엔시스)</li>
                  </ul>
                  <ul>
                    <li>홈페이지 통합유지보수 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>공간정보시스템전산장비 유지보수 용역 (밀양시)</li>
                  </ul>
                  <ul>
                    <li>국가 건물에너지통합관리시스템 구축사업(3차) 용역 (국토부-솔리데오시스템즈)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      case "성장기":
        return (
          <div className="historyListText" id="hst">
            <div className="hsT">
              <span>INSIGHT INFO </span>
              <p>2010 ~ 2014</p>
            </div>
            <div className="hsTCon">
              <div className="hsT2 hsT2Top">
                <div className="hsT2Year">2014</div>
                <div className="hsT2Month">
                  <ul>
                    <li> 3D과학체험관 디지털게임 콘텐츠 구축 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li> 온-나라시스템 이중화를 위한 소프트웨어 구입 (양산시)</li>
                  </ul>
                  <ul>
                    <li>국가건물에너지 통합관리시스템 구축(3차)-14년 (국토부-삼성SDS)</li>
                  </ul>
                  <ul>
                    <li>도서관 통합 홈페이지 기능개선 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>양산시 행정정보시스템 통합유지보수(UIS) 용역 (양산시-LG엔시스)</li>
                  </ul>
                  <ul>
                    <li>공간정보시스템 전산장비 유지보수 용역 (밀양시)</li>
                  </ul>
                  <ul>
                    <li>공통기반 시스템 주기억장치 증설 (양산시)</li>
                  </ul>
                  <ul>
                    <li>2014년 시립도서관 전산서버 유지보수 용역 (양산시)</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2013</div>
                <div className="hsT2Month">
                  <ul>
                    <li>2013년 양산시 생활공감지도서비스 유지보수 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>도시정보시스템(C/S) 유지보수(창원시-선도소프트)</li>
                  </ul>
                  <ul>
                    <li>국가 건물에너지 통합관리시스템 구축 3차 (국토해양부-아이씨티웨이)</li>
                  </ul>
                  <ul>
                    <li>공간정보시스템 전산장비 유지보수용역 (밀양시)</li>
                  </ul>
                  <ul>
                    <li>행정정보시스템 통합유지보수 용역 (양산시- LG엔시스)</li>
                  </ul>
                  <ul>
                    <li>환지검색시스템 고도화 사업 (양산시 - (주)인프라네트웍)</li>
                  </ul>
                  <ul>
                    <li>공단본부 홈페이지 유지보수용역 (양산시 시설관리공단)</li>
                  </ul>
                  <ul>
                    <li>도서관 홈페이지 유지보수용역 (양산시)</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2012</div>
                <div className="hsT2Month">
                  <ul>
                    <li>유물전시관 홈페이지 구축 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>국가건물에너지통합관리시시스템 구축 3차 (국토해양부 - 아이씨티웨이)</li>
                  </ul>
                  <ul>
                    <li>BEMS 솔루션모듈 개발 (국토해양부 - 유아트)</li>
                  </ul>
                  <ul>
                    <li>양산시 성과관리시스템 기능개선용역 (양산시 - 포와인드시스템)</li>
                  </ul>
                  <ul>
                    <li>평생학습센터 홈페이지 개편 용역 (양산시)</li>
                  </ul>
                  <ul>
                    <li>양산시시정빨래터 기능개선 사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>공간정보시스템전산장비 유지보수 용역 (밀양시)</li>
                  </ul>
                  <ul>
                    <li>양산시 행정정보시스템 통합유지보수 (양산시 - LG엔시스)</li>
                  </ul>
                  <ul>
                    <li>공단본부 홈페이지 유지보수 용역 (양산시 시설관리공단)</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2011</div>
                <div className="hsT2Month">
                  <ul>
                    <li>거제시지리정보시스템 유지보수 용역 (거제시 - 한진정보통신)</li>
                  </ul>
                  <ul>
                    <li>양산시 농촌체험포탈 홈페이지 구축 (양산시농업기술센터)</li>
                  </ul>
                  <ul>
                    <li>창원시 도시정보시스템 통합구축용역 (창원시 - 선도소프트)</li>
                  </ul>
                  <ul>
                    <li>공간정보 오픈플랫폼 운영자사이트 구축 용역 (지오투정보기술)</li>
                  </ul>
                  <ul>
                    <li>표준기록관리시스템용 중계소프트웨어 업그레이드 (양산시)</li>
                  </ul>
                  <ul>
                    <li>파주시공간정보고도화 사업 (파주시)</li>
                  </ul>
                  <ul>
                    <li>행정정보시스템 - UIS 통합유지보수 (양산시 - LG엔시스)</li>
                  </ul>
                  <ul>
                    <li>국가 건물에너지통합관리시스템 구축 2차 (국토해양부 - 벽산정보통신)</li>
                  </ul>
                  <ul>
                    <li>중소기업 마일리지 시스템 구축 사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>도시정보시스템 통합유지보수 용역 (창원시 - LG엔시스)</li>
                  </ul>
                  <ul>
                    <li>통합문화스포츠센터 홈페이지 구축 사업 (양산시 시설관리공단)</li>
                  </ul>
                  <ul>
                    <li>공단본부 홈페이지 유지보수 용역 사업 (양산시 시설관리공단)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      case "진입기":
        return (
          <div className="historyListText" id="hst">
            <div className="hsT">
              <span>INSIGHT INFO </span>
              <p>2006 ~ 2010</p>
            </div>
            <div className="hsTCon">
              <div className="hsT2 hsT2Top">
                <div className="hsT2Year">2010</div>
                <div className="hsT2Month">
                  <ul>
                    <li>거제시지리정보시스템 유지보수 용역 (거제시 - 한진정보통신)</li>
                  </ul>
                  <ul>
                    <li>옥외광고물통합관리시스템 개발 용역 (㈜명진아이앤씨)</li>
                  </ul>
                  <ul>
                    <li>포천시 지리정보시스템(GIS) 구축용역 (포천시 - 아이유시스)</li>
                  </ul>
                  <ul>
                    <li>국가건물에너지 통합관리시스템 구축 사업 (국토해양부 - 삼성SDS)</li>
                  </ul>
                  <ul>
                    <li>국가공간정보체계확산사업 (국토해양부 - 삼성SDS)</li>
                  </ul>
                  <ul>
                    <li>도로명주소 및 공간정보업무를 위한 도시정보시스템 기능개선 사업 (양산시 - ㈜동광지엔티)</li>
                  </ul>
                  <ul>
                    <li>양산시 시정빨래터시스템사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>경상남도 수산자원연구소 홈페이지 웹접근성 및 디자인 개선사업</li>
                  </ul>
                  <ul>
                    <li>파주시 공간정보시스템 통합 유지보수 용역사업 (파주시)</li>
                  </ul>
                  <ul>
                    <li>양산시 통합유지보수 - UIS 및 홈페이지 유지보수용역 (양산시 - LG엔시스)</li>
                  </ul>
                  <ul>
                    <li>진해시 도시정보시스템 응용 S/W 유지보수 용역 사업 (진해시)</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2009</div>
                <div className="hsT2Month">
                  <ul>
                    <li>전주시 온라인도로굴착시스템 기능개선 사업요역 (전주시 - 중앙항업)</li>
                  </ul>
                  <ul>
                    <li>공간정보관리 기반시스템 구축 2차 시범사업 용역 (국토부 - 삼성SDS)</li>
                  </ul>
                  <ul>
                    <li>부산시 IT기반 풍수해 예측 및 대응시스템 구축사업용역 (부산시 - GMK)</li>
                  </ul>
                  <ul>
                    <li>고성군 통계DB검색시스템구축사업 (고성군)</li>
                  </ul>
                  <ul>
                    <li>부동산정보관리시스템 통합사업 용역 (국토부 - 삼성SDS)</li>
                  </ul>
                  <ul>
                    <li>양산시 통합유지보수 - UIS 및 홈페이지 유지보수 용역 (양산시 - LG엔시스)</li>
                  </ul>
                  <ul>
                    <li>공중화장실관리시스템 구축 사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>양산문화예술포털 등 유지보수 (양산시)</li>
                  </ul>
                  <ul>
                    <li>부산시 통합유지보수 - 도시정보시스템 등 유지보수 (부산시 - 삼성SDS)</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2008</div>
                <div className="hsT2Month">
                  <ul>
                    <li> 가로수녹지공원관리 전산화 시스템구축 (부산시)</li>
                  </ul>
                  <ul>
                    <li>자동차과태료 인터넷납부시스템구축 (양산시)</li>
                  </ul>
                  <ul>
                    <li>부동산정보관리시스템 이관사업 용역 (국토부 - 삼성SDS)</li>
                  </ul>
                  <ul>
                    <li>양산 문화예술회관홈페이지 개편 사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>양산시 성과관리시스템 기능보완 사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>고객관리시스템 개편사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>광교신도시 U-City 설계 및 구축사업 실시설계 (경기도시공사 - 삼성SDS)</li>
                  </ul>
                  <ul>
                    <li>사이버평생학습센터 홈페이지개편 사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>통계홈페이지 구축 (양산시)</li>
                  </ul>
                  <ul>
                    <li>양산시 통합유지보수 - 시홈페이지유지보수용역 (양산시 - LG엔시스)</li>
                  </ul>
                  <ul>
                    <li>국토정보센터 시스템통합 및 공간정보관리체계 구축을 위한 ISP 수립 (국토부 - 삼성SDS)</li>
                  </ul>
                  <ul>
                    <li>양산시 3차원 생활지리정보시스템 구축 용역 (양산시 - 삼성 SDS)</li>
                  </ul>
                  <ul>
                    <li>양산시통합유지보수 - 도시정보시스템 유지보수 용역 (양산시 - LG엔시스)</li>
                  </ul>
                  <ul>
                    <li>평택시 생활지리정보시스템 구축 용역 (평택시 - 삼성SDS)</li>
                  </ul>
                  <ul>
                    <li>UIS를 연계한 새올행정시스템고도화 구축사업 (양산시)</li>
                  </ul>
                  <ul>
                    <li>양산시홈페이지 유지보수 (양산시)</li>
                  </ul>
                  <ul>
                    <li>공주시 지리정보시스템 컨설팅용역 (공주시 - 삼성SDS)</li>
                  </ul>
                </div>
              </div>
              <div className="hsT2">
                <div className="hsT2Year">2007</div>
                <div className="hsT2Month">
                  <ul>
                    <li>양산시도로와 지하시설물도 공동 구축사업 (양산시 - 삼성SDS)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return <>{setHistoryText()}</>;
};

export default HistoryList;
