import React from 'react'
import Submenu from 'component/template/submenu';
import { Link } from 'react-router-dom';

const AiMode = () => {
  return (
    <>
      <section className="section">
        <div className="subVisual subVisual02">BUSINESS</div>
        <Submenu page={"business"} sub={"ai"} />
        {/*컨텐츠 시작*/}
        <div className="tabBox">
          <ul>
            <li>
              <Link to="/business/aiLearningData" state={{ scrollToSub: true }}>AI학습데이터</Link>
            </li>
            <li className="tabChoice">
              <Link to="/business/aiMode" state={{ scrollToSub: true }}>AI Model</Link>
            </li>
            <li>
              <Link to="/business/chatGPT" state={{ scrollToSub: true }}>Chat GPT</Link>
            </li>
          </ul>
        </div>
        <div className="inner subInner">
          <div className="bu_topBox">
            <p className="bu_topText delay">
              <span className="ai_pointText">인공지능 모델 (AI Model)이란?</span> 데이터 세트(영상, 소리, 텍스트 등의 데이터 세트)로부터 목표하는 작업을
              수행하기 위해 기계 학습 알고리즘을 이용하여 추출된 특정 패턴을 말합니다. 이렇게 만들어진 인공지능 모델은 학습된 패턴에 대한 저장 파일과 수학적으로
              모델링된 알고리즘을 구성되어 지며, 학습에 이용되지 않은 새로운 데이터 세트에 대한 예측을 만들 수 있습니다. 인공지능 모델은{" "}
              <span className="ai_pointText">학습된 데이터</span>와 <span className="ai_pointText">학습 알고리즘</span>으로 구성 됩니다. 학습된 모델은 학습용
              알고리즘에 따라 다양한 형태로 구분되어 질 수 있습니다. 수치 형태의 신경망 노드의 가중치 값으로 표현되거나, 통계 수치값으로 표현되거나, 규칙 형태
              등으로 표현이 될 수 있습니다. 또한, 특징에 대한 패턴을 저장한 값이 아닌 학습 알고리즘으로 이루어진 모델도 존재 할 수 있습니다. 최근 주로 이용되는
              딥러닝 알고리즘들의 경우, 인공지능 학습 모델은 네트워크의 구조와 학습된 가중치 값이 됩니다.{" "}
            </p>
            <div className="bu_topImg delay">
              <img src="images/index/buIcon04.png" alt="AI이미지" />
            </div>
          </div>
        </div>
        <div className="busineesBg">
          <div className="inner subInner">
            <div className="centerBar" />
            <p className="subTitle">음성인식모델</p>
            <div className="busineesBgT">대용량의 음성 데이터를 학습하여 연령대별 특징적 발화를 인식할 수 있는 음성 모델을 개발합니다.</div>
            <div className="ai01_textList">
              <div className="ai01_tLTitle">모델 설명</div>
              <div className="ai01_tLCon ai01_tLConHalf">
                <div className="ai_imgBox" style={{ marginRight: 10 }}>
                  <img style={{ border: "1px solid #ddd" }} src="images/sub/ai_img05.PNG" alt="음성인식모델" />
                </div>
                <span className="ai_textBox">
                  slang-stt(speech to text) 모델은 은어∙속어를 음성을 인식하여 텍스트로 변환하는 한국어 음성모델입니다. 은어와 속어가 포함되어 있는 연령대별
                  특징적 발화 음성 데이터를 전사하여 텍스트로 나타냅니다.{" "}
                </span>
              </div>
              <div className="ai01_tLTitle">
                모델 아키텍처 <span className="ai_pointText">(Model Architecture)</span>
              </div>
              <div className="ai01_tLCon ai01_tLConHalf">
                <span className="ai_textBox">
                  wav2vec 2.0은 2020년 페이스북에서 개발하였으며, 입력한 원시 음성데이터를 기반으로 자기지도학습을 거쳐 데이터를 보다 정확하게 인식하는 음성
                  모델입니다. 한국어를 포함한 51개의 언어로 pre-trained 되어 있으며, 적은 양의 데이터로도 높은 정확도를 보이는 음성 인식 모델을 구축할 수
                  있습니다. 기존의 VQ wav2vec보다 안정적인 아키텍처를 가졌으며, 학습된 모델을 다양한 작업에 활용할 수 있습니다. wav2vec 2.0은 일정 거리에 위치한
                  벡터를 예측하는 CPC(Contrastive Predictive Coding) 방법론과 일정 부분이 가려진 데이터를 트랜스포머 인코더에 입력한 후 그 부분이 무엇인지를
                  예측하는 mask prediction을 수행하는 MLM(Masked Language Modeling) pre-training 방법론을 사용하여 사전학습을 진행하였습니다. 사전학습 모델에
                  원하는 작업을 수행하도록 fine-tuning하여 모델을 구성할 수 있습니다.{" "}
                </span>
                <div className="ai_imgBox">
                  <img style={{ border: "1px solid #ddd" }} src="images/sub/ai_img06.PNG" alt="모델아키텍처" />
                </div>
              </div>
              <div className="ai01_tLTitle">학습 데이터셋</div>
              <div className="ai01_tLCon">
                연령대별 특징적 발화(은어∙속어 등) 원천 데이터는 AI-Hub 사이트에서 다운로드 가능합니다.
                <br />
                AI-Hub:{" "}
                <a href="https://aihub.or.kr/" style={{ color: "#1a379f" }} target="_blank">
                  https://aihub.or.kr/{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
}

export default AiMode;