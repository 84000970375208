import React from 'react'
import Submenu from 'component/template/submenu'
import { Link } from 'react-router-dom'

const AiNoiseLearningData = () => {
  return (
    <>
      <section className="section">
        <div className="subVisual subVisual02">BUSINESS</div>
        <Submenu page={"business"} sub={"ai"} />
        {/*컨텐츠 시작*/}
        <div className="tabBox">
          <ul>
            <li className="tabChoice">
              <Link to="/business/aiLearningData" state={{ scrollToSub: true }}>AI학습데이터</Link>
            </li>
            <li>
              <Link to="/business/aiMode" state={{ scrollToSub: true }}>AI Mode</Link>
            </li>
            <li>
              <Link to="/business/chatGPT" state={{ scrollToSub: true }}>Chat GPT</Link>
            </li>
          </ul>
        </div>
        <div className="inner subInner" style={{ marginBottom: "3em" }}>
          <div className="ai01_btn ai01_btnChoice">
            <Link to="/business/aiNoiseLearningData">극한 소음 음성 및 환경소리 AI학습데이터 구축</Link>
          </div>
        </div>
        <div className="busineesBg">
          <div className="inner subInner">
            <div className="titleBox ">
              <div className="centerBar" />
              <p className="subTitle">소음 음성 및 환경 소리 AI학습데이터 구축</p>
            </div>
            <div className="busineesBgT">
              음성에 포함된 소음을 분리하고 제거하는 기술은 H/W적인 방법으로 노이즈캔슬링 기술이 적용된 제품이 상용화되었으나, <br />
              2채널 마이크 시스템의 환경이 아닌 경우 딥러닝 기반의 S/W 소음제거 알고리즘의 개발이 활발히 시도되고 있으며
              <br />
              딥러닝 기반의 소음제거기술의 발전을 위해서는 우선 다양한 소음환경에서 발성된 음성데이터의 구축이 선행되어, 소음과 음성에 대한 방대한 데이터의
              구축이 필요합니다.
            </div>
            <div className="ai01_tLTitle ai01_tLTitle02">필요성</div>
            <ul className="history01Text history01Text02">
              <li>
                인공지능이 소음을 제거하고 음성 및 특정 소리를 전달하기 위한 인공지능 개발을 위해 다양한 극한 소음 환경에서 수집된 대규모의 소음 및 음성, 소리
                데이터 구축 필요
              </li>
              <li>
                특정 소음에 편향되지 않도록 소음환경별 균형 있는 비율로 소음 및 소음 환경에서 녹음 된 음성데이터 및 소리데이터를 수집하고, 추후 데이터 공개 시
                문제 발생 요소를 제외하는 정제 작업이 필수로 요구됨
              </li>
              <li>소음 환경에서 녹음 된 음성데이터를 일관된 원칙으로 전사 및 검사하고, 고품질 데이터 확보를 위한 전수 검사 작업이 필수</li>
              <li>디지털 대전환의 가속화로 국내 기업·기관의 경쟁력 향상을 위한 음성인식 AI산업 기반 조성 및 활성화</li>
              <li>음성 기술은 음성 그 자체를 인터페이스로 손을 자유롭게 하고, 편리함을 극대화</li>
              <li>DNN 기반의 음향모델에 다양한 잡음환경 요인에 대한 학습 방법을 적용하여 잡음 환경에서도 강인한 인식성능이 나오도록 연구 개발이 필요</li>
            </ul>
            <div className="titleBox titleBoxMt">
              <div className="centerBar" />
              <div className="subTitle">음성인식기 국내 및 해외 동향</div>
            </div>
            <div className="ai01Sub ai01SubW">
              <div className="ai01SubText ai01SubTextL">
                <div className="ai01SubText01">
                  <div className="ai01_tLTitle ai01_tLTitle03">국내 동향</div>
                  <ul className="history01Text history01Text03">
                    <li>
                      한국전자통신연구원은 2016년 한국어 음성인식 기술을 개발하여, 지니톡 대국민 시범서비스 및 2018년 평창 올림픽 서비스 지원을 통해 실증 검증
                    </li>
                    <li>KAIST는 2021년 초고감도 인공기능 기반 화자 식별 및 음성 보안기술을 만들 수 있는 공진형 유연 압전 음성 센서를 개발</li>
                    <li>
                      국내 음성인식 연구는 대기업 중심으로 이루어지고 있으며, 가전 업체인 삼성전자, LG전자, 통신회사인 SK텔레콤, KT, LG유플러스, 인터넷 플랫폼
                      기업인 네이버, 카카오가 음성인식 플랫폼 연구 활발
                    </li>
                  </ul>
                </div>
                <div className="ai01SubText02">
                  <div className="ai01_tLTitle ai01_tLTitle03">해외 동향</div>
                  <ul className="history01Text history01Text03">
                    <li>
                      해외 음성인식 연구는 플랫폼 기업 중심으로 이루어지고 있으며, 음성인식 시장 확대를 위한 보안 및 프라이버시 문제 해결, 음성인식 성능 향상,
                      다양한 응용 서비스 개발이 활발
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ai01SubImg ai01SubImgR">
                <img src="images/sub/ai_img04.png" />
              </div>
            </div>
            <div className="titleBox titleBoxMt">
              <div className="centerBar" />
              <div className="subTitle">데이터 구축환경</div>
            </div>
            <div className="dataImg">
              <img style={{ border: "1px solid #ddd", marginTop: "2em" }} src="images/sub/ai_img07.png" alt="데이터구축이미지" />
            </div>
            <div className="titleBox titleBoxMt subLastBottom">
              <div className="centerBar" />
              <div className="subTitle">음원 획득 장치</div>
            </div>
          </div>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
}

export default AiNoiseLearningData