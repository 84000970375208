import axios from "service/Axios";
import Config from "Config";

const getBoards = () => {
  return axios.get(
    Config.url + Config.contextPath + "/api/board/",

    {
      headers: { "Content-Type": `application/json` },
    }
  );
};

const getBoardByCode = (code) => {
  return axios.get(Config.url + Config.contextPath + "/api/board/" + code, {
    headers: { "Content-Type": `application/json` },
  });
};

const saveBoard = (board) => {
  return axios.post(
    Config.url + Config.contextPath + "/api/board/save",
    board,
    {
      headers: { "Content-Type": `application/json` },
    }
  );
};

const deleteBoard = (id) => {
  return axios.post(
    Config.url + Config.contextPath + "/api/board/delete/"+id,
    {
      headers: { "Content-Type": `application/json` },
    }
  );
};

const board = {
  getBoards,
  getBoardByCode,
  saveBoard,
  deleteBoard,
};

export default board;
