import React from "react";
import Submenu from "component/template/submenu";

const About = () => {
  return (
    <>
      <section className="section">
        <div className="subVisual subVisual01">COMPANY</div>
        <Submenu page={"company"} sub={"about"} />
        {/*컨텐츠 시작*/}
        <div className="inner subInner">
          <div className="aboutCon01">
            <div className="about01L delay">
              창의와 혁신
              <br />
              도전과 열린 마음을 가진
              <br />
              사람 중심의 기업
            </div>
            <div className="about01R delay">
              <img src="images/sub/about02.png" alt="로고" />
            </div>
          </div>
          <div className="aboutCon02">
            인사이트정보는 공간정보를 바탕으로한 지리정보서비스를 제공하는 전문 GIS &amp; web Service Provider로서, <br />
            다년간 공공기관 중심의 도시정보시스템(UIS) 및 지리정보시스템(GIS)분야 수행 경험과 노하우를 가진 전문인력들로 구성되어 있습니다.
            <br />
            <br />
            현재 여러 지자체에서 지리정보시스템 구축 및 모바일 시스템 구축 사업 등을 수행하고 있으며, 또한 GIS분야 뿐만 아니라, <br />
            웹 서비스 분야로 사업 영역을 확장에 나가고 있습니다. <br />
            향후 공간정보를 기반으로 한 GIS 및 Mobile &amp; Web Service 전문업체로 성장할 것입니다.
          </div>
        </div>
        <div className="aboutCon03">
          <div className="inner subInner">
            <div className="aC02Img">
              <img src="images/sub/about_or.png" alt="조직도" />
            </div>
          </div>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
};

export default About;
