import React from 'react'
import Submenu from 'component/template/submenu';
import { Link } from 'react-router-dom';

const ChatGPT = () => {
  return (
    <>
      <section className="section">
        <div className="subVisual subVisual02">BUSINESS</div>
        <Submenu page={"business"} sub={"ai"} />
        {/*컨텐츠 시작*/}
        <div className="tabBox">
          <ul>
            <li>
              <Link to="/business/aiLearningData" state={{ scrollToSub: true }}>
                AI학습데이터
              </Link>
            </li>
            <li>
              <Link to="/business/aiMode" state={{ scrollToSub: true }}>
                AI Model
              </Link>
            </li>
            <li className="tabChoice">
              <Link to="/business/chatGPT" state={{ scrollToSub: true }}>
                Chat GPT
              </Link>
            </li>
          </ul>
        </div>
        <div className="inner subInner">
          <div className="bu_topBox">
            <p className="bu_topText delay">
              ChatGPT는 OpenAI가 2022년 11월에 발표한 GPT 구조를 사용한 대화형 인공지능 모델로 2022년 발표된 GPT-3.5를 기반으로 만들어졌습니다. 사용자가
              제공하는 문장(프롬프트)을 기반으로 텍스트를 생성하여 사용자를 돕는 것이 ChatGPT의 주 기능입니다. GPT는 트랜스포머 모델의 디코더 부분을 가져와
              사용하였습니다. 트랜스포머는 셀프 어텐션 기술을 사용하여 문장 내의 단어들 사이의 관계를 이해하고, 이를 기반으로 문장의 맥락과 의미를 학습하는
              모델입니다. ChatGPT는 회화형 데이터셋을 사용하여 파인튜닝 되어 대화적 성격의 문맥에 맞춰 자연스러운 텍스트를 생성하기 위해 매개변수가 최적화 되어
              있어 챗봇을 만드는 데 적합합니다.
            </p>
            <div className="bu_topImg">
              <img className="delay" src="images/index/buIcon04.png" alt="AI이미지" />
            </div>
          </div>
        </div>
        <div className="busineesBg">
          <div className="inner subInner">
            <div className="centerBar" />
            <p className="subTitle">언어 모델의 History</p>
            <div className="ai03_01 ai03_01_03 ai01_tLConHalf">
              <div className="history01Img">
                <img src="images/sub/ai_img02.png" alt="언어모델역사" />
              </div>
              <ul className="history01Text">
                <li>
                  RNN(Recurrent Neural Network)은 입력과 출력을 시퀀스 단위로 처리하는 시퀀스 모델로, 매 스텝의 아웃풋이 다음 스텝의 인풋으로 입력되어 이전
                  스텝의 정보에 접근이 가능합니다.
                </li>
                <li>
                  바닐라 RNN은 짧은 시퀀스에서만 효과를 보이고, 스텝이 길어지면 이전의 정보가 뒤로 충분히 전달되지 못하는 Vanishing Gradient라는 단점이
                  있습니다.
                </li>
                <br />
                <br />
                <li>
                  LSTM(Long Short-Term Memory)은 불필요한 기억을 지우고 기억해야 할 것들은 정하는 방식으로 Vanishing Gradient의 단점을 보완한 RNN 모델입니다.{" "}
                </li>
                <br />
                <br />
                <li>Seq2seq 모델은 인코더-디코더로 구성된 모델로 문장(단어들의 시퀀스)를 입력으로 받아 출력으로 다른 문장을 생성할 수 있습니다.</li>
                <li>
                  인코더는 입력 문장의 모든 단어들을 순차적으로 입력 받은 뒤 마지막에 모든 단어 정보를 압축하여 마지막 시점에 컨텍스트 벡터라는 하나의 벡터로
                  만듭니다. 이 컨텍스트 벡터는 디코더로 전송되며, 디코더는 컨텍스트 벡터를 이용하여 새로운 단어를 하나씩 출력합니다.
                </li>
                <li>Seq2seq에서 인코더와 디코더는 RNN 모델인 LSTM으로 구성되어 있습니다.</li>
                <li>디코더는 다음에 올 단어를 예측하고, 그 예측한 단어를 다음 시점의 RNN 셀의 입력으로 넣는 행위를 반복합니다.</li>
                <li>Seq2seq는 입력 시퀀스가 주어졌을 때 올바른 출력 시퀀스를 출력할 확률성을 높이는 방식으로 훈련됩니다.</li>
                <br />
                <br />
                <li>어텐션은 시퀀스 요소들 가운데 태스크 수행에 중요한 요소에 집중하는 것입니다.</li>
                <li>트랜스포머에 사용되는 어텐션은 셀프 어텐션 기법으로, 입력 시퀀스 가운데 태스크 수행에 의미 있는 요소 위주로 정보를 추출할 수 있습니다.</li>
                <li>
                  기존 seq2seq 모델은 인코더가 입력 시퀀스를 하나의 벡터로 압축하는 과정에서 입력 시퀀스의 정보가 일부 손실되는 문제점이 있었는데, RNN 대신 셀프
                  어텐션 기법을 사용하여 디코더가 맨 마지막의 컨텍스트 벡터에만 접근하는 대신 모든 입력 단어에 접근할 수 있게 되었습니다.
                </li>
                <br />
                <br />
                <li>트랜스포머는 2017년 구글에서 발표한 셀프 어텐션을 사용하는 sequence-to-sequence 모델입니다.</li>
                <li>
                  트랜스포머는 셀프 어텐션 기술을 사용하여 문장 내의 단어들 사이의 관계를 이해하고, 이를 기반으로 문장의 맥락과 의미를 학습할 수 있는
                  모델입니다.
                </li>
                <li>트랜스포머의 인코더는 소스 시퀀스 간의 관계 정보를 담은 히든 벡터를 출력합니다.</li>
                <li>트랜스포머의 디코더는 인코더가 보내준 히든 벡터를 받아 매번 인풋 시퀀스의 각기 다른 부분에 집중하며 단어를 출력합니다.</li>
                <li>트랜스포머는 기계번역과 시계열 예측에 뛰어난 성능을 보이며, GPT와 BERT, chatGPT 같은 다양한 자연어처리 모델들에 사용됩니다.</li>
                <br />
                <br />
                <li>GPT는 Generative Pre-trained Transformer의 약자로 대규모의 데이터로 훈련하여 자연어의 표현을 학습한 인공지능 모델입니다.</li>
                <li>GPT는 파인튜닝을 통하여 번역, 텍스트 요약, 텍스트 생성과 같은 다양한 자연어 처리 태스크를 수행할 수 있습니다.</li>
                <li>GPT는 트랜스포머의 디코더 부분을 사용하여 이전에 생성된 단어의 문맥에 기반하여 새로운 단어를 매번 생성해냅니다.</li>
                <li>GPT는 다음에 올 단어를 예측하는 비지도 학습 방식으로 훈련되었습니다.</li>
              </ul>
            </div>
            <hr className="ai03Hr" />
            <div className="ai03_01 ai03_01_02 ai03_01_03 ai01_tLConHalf">
              <div className="history01Img">
                <img src="images/sub/ai_img03.png" alt="gpt-4" />
              </div>
              <ul className="history01Text">
                <li>GPT-4는 GPT의 4세대 모델로 2023년3월 openAI에서 발표한 언어 모델입니다.</li>
                <li>GPT-4는 GPT-3.5에 비해 Speed는 떨어졌지만 Reasoning과 Conciseness가 많이 올라간 것으로 보입니다.</li>
                <li>
                  GPT-3.5는 1750억개의 파라미터와 96개의 디코더 레이어를 갖고 있으며 GPT-4의 파라미터는 GPT-3.5보다 증가 되었지만 어느정도인지 공개 되지
                  않았습니다.
                </li>
                <li>
                  GPT-4의 가장 큰 특징은 텍스트 뿐만 아니라 음성, 이미지, 영상까지 처리하는{" "}
                  <span className="ai_pointText">멀티모달 시스템(Multimodal System)</span> 입니다.
                </li>
                <li>GPT-3.5의 경우에는 텍스트 3,000 단어까지 처리가 가능했으나 GPT-4는 8배 증가한 25,000 단어까지 처리가 가능합니다</li>
              </ul>
            </div>
            <div className="ai03_02">
              <div className="ai03_02Img">
                <img src="images/gpt.gif" alt="gpt타이핑이미지" />
              </div>
              <div className="ai03_02Arrow">
                <div className="ai03_02Arrow_">
                  <p>
                    <img src="images/multimodal1.png" alt="멀티모달1이미지" />
                  </p>
                </div>
                <div className="ai03_02Arrow_ ai03_02Arrow_2">
                  <p>
                    <img src="images/multimodal2.png" alt="멀티모달2이미지" />
                  </p>
                </div>
                <p style={{ fontSize: 13, marginTop: 20 }}>[예시] 멀티모달 시스템 (Multimodal System)</p>
              </div>
            </div>
          </div>
        </div>
        {/*컨텐츠 끝*/}
      </section>
    </>
  );
}

export default ChatGPT;