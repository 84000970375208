import { userInfoState } from '_states/atom';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

const AdminHeader = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [leftTime, setLeftTime] = useState("");

  const isIntervalStop = useRef(false);
  const navigate = useNavigate();

  const logout = () => {
    if (userInfo) {
      localStorage.removeItem("userInfo");
      setUserInfo(null);
      setLeftTime("");
    }
  }

  const renderingLeftTime = (diff) => {
    // const diffDay = String(Math.floor(diff / (1000 * 60 * 60 * 24))) || String("0");
    // const diffHour = String(Math.floor((diff / (1000 * 60 * 60)) % 24)).padStart(2, "0");
    const diffMin = String(Math.floor((diff / (1000 * 60)) % 60)).padStart(2, "0");
    const diffSec = String(Math.floor(diff / 1000 % 60)).padStart(2, "0");

    setLeftTime(`${diffMin}분 ${diffSec}초 후 자동 로그아웃 됩니다.`);
  }

  useEffect(() => {
    if (userInfo) {
      const interval = setInterval(() => {
        if (!isIntervalStop.current) {
          const expiryTime = new Date(userInfo.authentication.tokenExpiresIn);
          const todayTime = new Date();
          const diff = expiryTime.getTime() - todayTime.getTime();
          if (diff <= 1000) {
            //session time out
            isIntervalStop.current = true;
            setLeftTime("");
          }
          renderingLeftTime(diff);
        } else {
          //stop
          clearInterval(interval);
          logout();
        }
      }, 0);
      return () => {
        setLeftTime("");
        clearInterval(interval);
      }
    } else {
      return navigate("/qazx/login");
    }
  }, [userInfo]);

  return (
    <header className='adminHeader'>
      <div className="utilBox">
        <div className="user">
          <p className="autoLogout">{leftTime}</p>{" "}
          <img src="/images/user.png" alt="사용자" />
          <p>
            <span>{userInfo && userInfo.user.name}</span>님 반갑습니다.
          </p>{" "}
        </div>
        <ul className="util">
          <li>
            <Link to="/qazx/login"
              onClick={e => {
                e.preventDefault();
                if (window.confirm("로그아웃 하시겠습니까?")) {
                  logout();
                } else {
                  return;
                }
              }}>로그아웃</Link>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default AdminHeader;