import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "./navigation";
import LoginService from "service/user";
import { useRecoilState } from "recoil";
import { userInfoState } from "_states/atom";

function Login() {
  const [checkMessage, setCheckMessage] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const navigate = useNavigate();

  const onLoginButtonClick = (e) => {
    e.preventDefault();
    const data = {
      id: e.target.Id.value,
      password: e.target.Password.value,
    };

    LoginService.login(data)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200 && response.data.authentication) {
          localStorage.setItem("userInfo", JSON.stringify(response.data));
          setUserInfo(response.data);
          navigate('/qazx/board');
        }
      }).catch(error => {
        // console.log(error)
        if (error.response?.status === 401) {
          setCheckMessage(error.response.data.Message);
        } else {
          setCheckMessage('네트워크 연결 오류');
        }
      });
  };

  return (
    <>
      <Navigation />
      <section id="section" className="adminSection">
        {/*컨텐츠시작*/}
        <div className="contents">
          <div className="loginWrap">
            <div className="loginBox">
              <p className="loginImg">
                <img src="images/login.png" width={56} height={62} alt="로그인" />
              </p>
              <ul>
                <form onSubmit={onLoginButtonClick}>
                  <li>
                    <label>아이디</label>
                    <input type="text" placeholder="Id" name="Id" />
                  </li>
                  <li>
                    <label>비밀번호</label>
                    <input type="password" placeholder="Password" name="Password" />
                  </li>
                  <li>
                    <button type="submit">로 그 인</button>
                  </li>
                </form>
              </ul>
              {checkMessage ? <p className="loginRedText">{checkMessage}</p> : <></>}
            </div>
          </div>
        </div>
        {/*컨텐츠끝*/}
      </section>
    </>
  );
}

export default Login;
