import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import anime from "animejs/lib/anime.es.js";

const Carousel = () => {
  const [SlideNumber, setSlideNumber] = useState(0);
  const [Pause, setPause] = useState(false);

  const slider = useRef(null);
  const animation = useRef();

  const letters1 = () => {
    const inHtml = `창의와 혁신 `;
    const htms = inHtml.replace(/\S/g, `<span class='letter'>$&</span>`);
    return <div dangerouslySetInnerHTML={{ __html: htms }}></div>;
  };

  const letters2 = () => {
    const inHtml = `도전과 열린 마음`;
    const htms = inHtml.replace(/\S/g, `<span class='letter'>$&</span>`);
    return <div dangerouslySetInnerHTML={{ __html: htms }}></div>;
  };

  const letters3 = (page) => {
    let inHtml = "";
    switch (page) {
      case 1:
        inHtml = `OPEN-MINDED CHALLENGE`;
        break;
      case 2:
        inHtml = `CROWDSOURCING`;
        break;
      case 3:
        inHtml = `Continuous development and active response to change`;
        break;
      default:
        inHtml = `OPEN-MINDED CHALLENGE`;
        break;
    }
    const htms = inHtml.replace(/\S/g, `<span class='letter'>$&</span>`);
    return <div dangerouslySetInnerHTML={{ __html: htms }}></div>;
  };

  const letters4 = (page) => {
    let inHtml = "";
    switch (page) {
      case 1:
        inHtml = `인사이트정보는 창의적이고 도전적인 사고를 바탕으로,`;
        break;
      case 2:
        inHtml = `크라우드소싱을 통해,`;
        break;
      case 3:
        inHtml = `끊임없이 발전하고, 변화에 적극적으로 대응하며,`;
        break;
      default:
        inHtml = `인사이트정보는 창의적이고 도전적인 사고를 바탕으로,`;
        break;
    }

    const htms = inHtml.replace(/\S/g, `<span class='letter2'>$&</span>`);
    return <div dangerouslySetInnerHTML={{ __html: htms }}></div>;
  };

  const letters5 = (page) => {
    let inHtml = "";
    switch (page) {
      case 1:
        inHtml = `열린 마음으로 새로운 아이디어와 혁신적인 기술을 적극적으로 수용하며,`;
        break;
      case 2:
        inHtml = `다양한 분야의 전문가들과 함께 공동 작업을 수행하여,`;
        break;
      case 3:
        inHtml = `의적이고 도전적인 마인드를 유지하고 있습니다.`;
        break;
      default:
        inHtml = `열린 마음으로 새로운 아이디어와 혁신적인 기술을 적극적으로 수용하며,`;
        break;
    }
    const htms = inHtml.replace(/\S/g, `<span class='letter3'>$&</span>`);
    return <div dangerouslySetInnerHTML={{ __html: htms }}></div>;
  };

  const letters6 = (page) => {
    let inHtml = "";
    switch (page) {
      case 1:
        inHtml = `AI, 공간정보, 건설정보, 행정정보 분야에서 선도적인 위치를 차지하고 있습니다.`;
        break;
      case 2:
        inHtml = `인공지능 학습용 데이터 구축사업을 진행하고 있습니다.`;
        break;
      case 3:
        inHtml = `우리는 미래를 대비하여, 더 나은 세상을 만들기 위해 노력하고 있습니다.`;
        break;
      default:
        inHtml = `AI, 공간정보, 건설정보, 행정정보 분야에서 선도적인 위치를 차지하고 있습니다.`;
        break;
    }
    const htms = inHtml.replace(/\S/g, `<span class='letter4'>$&</span>`);
    return <div dangerouslySetInnerHTML={{ __html: htms }}></div>;
  };

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    fade: true,
    speed: 1,
    arrows: false,
  };

  const SlideAnimation = () => {
    animation.current = anime({
      targets: ".vT01 .letter",
      translateY: [50, 0],
      translateZ: 0,
      opacity: [0, 1],
      duration: 2000,
      delay: (el, i) => 50 * i,
    });

    animation.current = anime({
      targets: ".vT02 .letter",
      translateY: [30, 0],
      translateZ: 0,
      opacity: [0, 1],
      duration: 5000,
      delay: (el, i) => 50 * i,
    });

    animation.current = anime({
      targets: ".vT03 .letter2",
      opacity: [0, 1],
      easing: "easeInOutQuart",
      duration: 2000,
      delay: (el, i) => 10 * (i + 1),
    });

    animation.current = anime({
      targets: ".vT03 .letter3",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 2000,
      delay: (el, i) => 10 * (i + 1),
    });

    animation.current = anime({
      targets: ".vT03 .letter4",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 2000,
      delay: (el, i) => 10 * (i + 1),
    });

    animation.current = anime({
      targets: ".img02",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 1500,
    });

    animation.current = anime({
      targets: ".img01",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 1000,
    });

    animation.current = anime({
      targets: ".img04",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 2500,
    });

    animation.current = anime({
      targets: ".img05",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 2500,
    });
  };

  useEffect(() => {
    SlideAnimation();
  }, []);

  return (
    <>
      <Slider
        ref={slider}
        {...settings}
        beforeChange={(e) => {
          SlideAnimation();
        }}
        afterChange={(index) => {
          setSlideNumber(index);
        }}
      >
        <div>
          <>
            <div className="visualBox">
              <div className="visualText">
                <div className="vT01">
                  {letters1()}
                  {letters2()}
                </div>
                <div className="vT02">{letters3(1)}</div>
                <div className="vT03">
                  {letters4(1)}
                  {letters5(1)}
                  {letters6(1)}
                </div>
              </div>
              <div className="visualImg">
                <div className="img02">
                  <img src="images/index/visual02.png" alt="슬라이드이미지" />
                </div>
                <div className="img01">
                  <img src="images/index/visual01.png" alt="슬라이드이미지" />
                </div>
                {/*div class="img03"><img src="images/index/visual03.png"/></div*/}
                <div className="img04">
                  <img src="images/index/visual04.png" alt="슬라이드이미지" />
                </div>
                <div className="img05">
                  <img src="images/index/visual05.png" alt="슬라이드이미지" />
                </div>
              </div>
            </div>
          </>
        </div>
        <div>
          <>
            <div className="visualBox">
              <div className="visualText">
                <div className="vT01">
                  {letters1()}
                  {letters2()}
                </div>
                <div className="vT02">{letters3(2)}</div>
                <div className="vT03">
                  {letters4(2)}
                  {letters5(2)}
                  {letters6(2)}
                </div>
              </div>
              <div className="visualImg">
                <div className="img02">
                  <img src="images/index/visual02.png" alt="슬라이드이미지" />
                </div>
                <div className="img01">
                  <img src="images/index/visual01.png" alt="슬라이드이미지" />
                </div>
                {/*div class="img03"><img src="images/index/visual03.png"/></div*/}
                <div className="img04">
                  <img src="images/index/visual04.png" alt="슬라이드이미지" />
                </div>
                <div className="img05">
                  <img src="images/index/visual05.png" alt="슬라이드이미지" />
                </div>
              </div>
            </div>
          </>
        </div>
        <div>
          <>
            <div className="visualBox">
              <div className="visualText">
                <div className="vT01">
                  {letters1()}
                  {letters2()}
                </div>
                <div className="vT02 mmT">{letters3(3)}</div>
                <div className="vT03">
                  {letters4(3)}
                  {letters5(3)}
                  {letters6(3)}
                </div>
              </div>
              <div className="visualImg">
                <div className="img02">
                  <img src="images/index/visual02.png" alt="슬라이드이미지" />
                </div>
                <div className="img01">
                  <img src="images/index/visual01.png" alt="슬라이드이미지" />
                </div>
                {/*div class="img03"><img src="images/index/visual03.png"/></div*/}
                <div className="img04">
                  <img src="images/index/visual04.png" alt="슬라이드이미지" />
                </div>
                <div className="img05">
                  <img src="images/index/visual05.png" alt="슬라이드이미지" />
                </div>
              </div>
            </div>
          </>
        </div>
      </Slider>
      <div className="vTBtn">
        <ul className="vTBtnDot">
          <li className={SlideNumber === 0 ? "vTDotChoice" : ""}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                slider?.current?.slickGoTo(0);
              }}
            />
          </li>
          <li className={SlideNumber === 1 ? "vTDotChoice" : ""}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                slider?.current?.slickGoTo(1);
              }}
            />
          </li>
          <li className={SlideNumber === 2 ? "vTDotChoice" : ""}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                slider?.current?.slickGoTo(2);
              }}
            />
          </li>
        </ul>
        <ul className="vTBtnPlay">
          <li>
            <img style={{ cursor: "pointer" }} onClick={() => slider?.current?.slickPrev()} src="images/index/arrowLeft.png" alt="좌측화살표" />
          </li>
          <li>
            {
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  Pause ? slider.current.slickPlay() : slider.current.slickPause();
                  setPause(!Pause);
                }}
                src="images/index/stop.png"
                alt="재생정지버튼"
              />
            }
          </li>
          <li>
            <img style={{ cursor: "pointer" }} onClick={() => slider?.current?.slickNext()} src="images/index/arrowRight.png" alt="우측화살표" />
          </li>
        </ul>
      </div>
    </>
  );
};

export default Carousel;

// {/* <div>
//           <>
//             <div className="visualBox">
//               <div className="visualText">
//                 <div className="vT01">
//                   창의와 혁신2
//                   <br />
//                   도전과 열린 마음
//                 </div>
//                 <div className="vT02">OPEN-MINDED CHALLENGE</div>
//                 <div className="vT03">
//                   인사이트정보는 창의적이고 도전적인 사고를 바탕으로, <br />
//                   열린 마음으로 새로운 아이디어와 혁신적인 기술을 적극적으로 수용하며, <br />
//                   공간정보, 건설정보, 행정정보 분야에서 선도적인 위치를 차지하고 있습니다.
//                 </div>
//               </div>
//               <div className="visualImg">
//                 <div className="img02">
//                   <img src="images/index/visual02.png" />
//                 </div>
//                 <div className="img01">
//                   <img src="images/index/visual01.png" />
//                 </div>
//                 {/*div class="img03"><img src="images/index/visual03.png"/></div*/}
//                 <div className="img04">
//                   <img src="images/index/visual04.png" />
//                 </div>
//                 <div className="img05">
//                   <img src="images/index/visual05.png" />
//                 </div>
//               </div>
//             </div>
//           </>
//         </div>
//         <div>
//           <>
//             <div className="visualBox">
//               <div className="visualText">
//                 <div className="vT01">
//                   창의와 혁신3
//                   <br />
//                   도전과 열린 마음
//                 </div>
//                 <div className="vT02">OPEN-MINDED CHALLENGE</div>
//                 <div className="vT03">
//                   인사이트정보는 창의적이고 도전적인 사고를 바탕으로, <br />
//                   열린 마음으로 새로운 아이디어와 혁신적인 기술을 적극적으로 수용하며, <br />
//                   공간정보, 건설정보, 행정정보 분야에서 선도적인 위치를 차지하고 있습니다.
//                 </div>
//               </div>
//               <div className="visualImg">
//                 <div className="img02">
//                   <img src="images/index/visual02.png" />
//                 </div>
//                 <div className="img01">
//                   <img src="images/index/visual01.png" />
//                 </div>
//                 {/*div class="img03"><img src="images/index/visual03.png"/></div*/}
//                 <div className="img04">
//                   <img src="images/index/visual04.png" />
//                 </div>
//                 <div className="img05">
//                   <img src="images/index/visual05.png" />
//                 </div>
//               </div>
//             </div>
//           </>
//         </div> */}
