import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Topnavigation from "./topnavigation";
import { showModal } from "_states/atom";
import { useRecoilState } from "recoil";
import { animateScroll as scroll } from "react-scroll";

import BoardService from "service/board";

function Header() {
  const [ShowModal, setShowModal] = useRecoilState(showModal);

  const location = useLocation();
  const path = location.pathname.includes("qazx");

  const [isMobile, setIsMobile] = useState(false);
  const [boardList, setBoardList] = useState([]);

  const ShowVisibleModal = (e) => {
    e.preventDefault();
    setShowModal(!ShowModal);
  };

  useEffect(() => {
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    );
    BoardService.getBoards().then((response) => {
      setBoardList(response.data);
    });
  }, []);

  useEffect(() => {
    if (!location.state) scroll.scrollToTop();
  }, [location]);

  return path ? (
    <></>
  ) : (
    <header>
      <div className="topHeader">
        <div className="inner topHeaderInner">
          <Link to="/" className="logoBox">
            <img src="images/logo.png" alt="로고" />
          </Link>
          <div className="menuWrap">
            <ul className="menuBox">
              <li className="headerMenu">
                <Link to="/company/about">COMPANY</Link>
                <div className="menu2Dept">
                  <ul>
                    <li>
                      <Link to="/company/about">
                        <span>About Us</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/company/history">
                        <span>CEO &amp; History</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/company/location">
                        <span>오시는길</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="headerMenu">
                <Link to="/business/aiLearningData">BUSINESS</Link>
                <div className="menu2Dept">
                  <ul>
                    <li>
                      <Link to="/business/aiLearningData">
                        <span>AI</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/gisService">
                        <span>GIS Service</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/webService">
                        <span>WEB Service</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/business/crowdSourcing">
                        <span>CROWD Sourcing</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="headerMenu">
                <Link to="/support/notice">SUPPORT</Link>
                <div className="menu2Dept">
                  <ul>
                    {boardList
                      .filter((board) => board.useYn === true)
                      .map((board) => {
                        return (
                          <li>
                            <Link to={`/support/${board.code}`}>
                              <span>{board.name}</span>
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </li>
            </ul>
            <div className="menuBtn">
              <img
                style={{ cursor: "pointer" }}
                src="images/menu.png"
                onClick={ShowVisibleModal}
                alt="메뉴버튼"
              />
            </div>
          </div>
        </div>
      </div>

      {ShowModal && <Topnavigation isMobile={isMobile} />}
    </header>
  );
}

export default Header;
